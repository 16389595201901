import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn'; 
import { LoadFadeIn } from '../LoadFadeIn'; 
import ReactGA from 'react-ga';

const ResearchRequest = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="research-request-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src="assets/research/ochc-lib.webp" alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Research Services</h1>
                </div>            
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <p className='fade-in-up'>
                    Can't make it to the library or seek some help with your research? We can investigate materials 
                    available in our collections and respond to your historical or genealogical research request 
                    for a small prepaid fee. Photocopies of primary resources to assist you with your research are 
                    provided. Additional documents and image reproductions are available for a separate fee.
                    </p>
                    <p className='fade-in-up'>
                    Please keep in mind that we do not guarantee we will locate the information that you are seeking. 
                    It still takes time and effort to research even if we are not able to locate the information you 
                    are seeking.
                    </p>
                    <p className='fade-in-up'>
                    You will be notified of any additional costs before further research is conducted. Please 
                    contact the executive director to make arrangements for extensive research projects.
                    </p>
                    <br/>
                    <h2 className='fade-in-up'>Making a Research Request</h2>
                    <h5 className='fade-in-up'>Instructions:</h5>
                    <p className='fade-in-up'>All research requests must be made in writing and accompanied by the initial $25.00 fee. 
                        Requests can be submitted online or by mail. Please follow the directions below.
                    </p>
                    <h5 className='fade-in-up'>Online:</h5>
                    <p className='fade-in-up'>
                    Submit your $25.00 payment on-line using your credit card and the "Pay Now" button below. Next, select the "Return to Merchant" 
                    button to provide and submit your request information electronically. You will receive an e-mail confirmation of your payment. 
                    Important: Please provide your "shipping address" when requested to do so. 
                    Please contact <span><a href="research@oneidacountyhistory.org">research@oneidacountyhistory.org</a></span> with questions.
                    </p>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="4KGFM8X65NTPJ" />
                        <input type="hidden" name="currency_code" value="USD" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                    </form>                   

                    <br/><br/><br/>

                    <h5 className='fade-in-up'>By Mail:</h5>
                    <p className='fade-in-up'>
                    Print and complete a copy of our Research Request form. Mail the form, accompanied by your check made payable to the Oneida County History Center, to the address provided on the form.
                    </p>
                    <a className="main-button" 
                    href="https://6219ea23-509b-4182-8d95-79d79c599fa8.filesusr.com/ugd/2e782e_ed3af65a82494907af5c256d4d6dbd91.pdf">Request Research form
                    </a>    

                    <br/><br/><br/>

                    <h5 className='fade-in-up'>Please Note:</h5>
                    <p className='fade-in-up'>
                    We are a volunteer-run nonprofit, so keep in mind that the fees do not cover the cost of the service. 
                    We are able to subsidize the costs associated with researching and preserving the records in our 
                    possession through the generosity of our members.
                    </p>
                    <p className='fade-in-up'>
                    Also, we do not maintain records for vital statistics. To obtain birth, death, or marriage records, 
                    contact the clerk of the town or city where the birth, death, or marriage occurred. For wills, contact 
                    the county surrogate court. Visit <span><a className="research-request-link" href="https://www.ocgov.net//historicalrecords">https://www.ocgov.net//historicalrecords</a></span> for
                     more information on Oneida County records.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default ResearchRequest;