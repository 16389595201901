import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const OriskanyBattle = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Tues. Aug. 6 | Utica</h5>
                    <h1>Oriskany Battlefield Commemoration</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/oriskany_battle.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Tuesday, Aug. 6 at 7:00 p.m
                    <br/>
                    7801 New York 69 Oriskany, NY 13424
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Honor the 247th anniversary of the Battle of Oriskany at the Oriskany Battlefield State Historic Site. The battle is considered the bloodiest battle of the American Revolution and a turning point for the American Colonies' fight for freedom. A monument was dedicated on August 6, 1884, to serve as a memorial to those who fought so bravely and tenaciously to preserve freedom. Oriskany Battlefield was designated a New York State historic site in 1927. This ceremony remembers those who fought in this important battle. Hosted by the New York State Office of Parks, Recreation, and Historic Preservation, the battlefield is located at 7801 New York 69 Oriskany, NY 13424
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default OriskanyBattle;