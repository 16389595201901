import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const EasterPotato = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. March 30 | Utica</h5>
                    <h1>Easter Potato Stamp Drop-In Workshop</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/Easter-Egg-Potato-Stamping.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, March 30, 2024, 10 A.M. to 2:00 P.M. 
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    In this fun workshop, kids ages 6–12 will use potatoes to make easy and appealing stamp designs that they can use to create spring or Easter cards. Maria Proctor's Easter card collection will be on exhibit to inspire your artistic creation.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default EasterPotato;