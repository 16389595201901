import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const OpenHouseProctor = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. June 15 | Utica</h5>
                    <h1>Open House at the Proctor Memorial Building</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/openhouseproctor.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, June 15, 2024, 10 A.M.
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Some believe the little stone building under the Genesee Street bridge is Bagg’s Hotel, but it’s
                    not! Maria Proctor erected this building to memorialize her husband, Thomas R. Proctor, and
                    their beloved Bagg’s Hotel. Join the History Center for an exclusive tour of the Proctor Memorial
                    Building and the history of Bagg Square at Bagg Commemorative Park.                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default OpenHouseProctor;