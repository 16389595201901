import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';
import './styles/App.css';
import './styles/about.css';
import './styles/footer.css';
import './styles/home.css';
import './styles/navbar.css';
import './styles/research.css';
import './styles/support.css';
import './styles/visit.css';

import App from './App';
import Home from './component/Home';
import Navbar from './component/Navbar';
import Footer from './component/Footer';

import './component/LoadFadeIn';
import './component/FadeIn';

// ABOUT 

import PageNotFound from './component/PageNotFound';

import Contact from './component/About/Contact';
import About from './component/About/About';
import News from './component/About/News';
import OurHistory from './component/About/OurHistory';

// NEWS PAGES

import NewsPage1 from './component/About/News/NewsPage1';
import NewsPage2 from './component/About/News/NewsPage2';
import NewsPage3 from './component/About/News/NewsPage3';
import NewsEllenLuley from './component/About/News/NewsEllenLuley';
import NewsFiveFamilies from './component/About/News/NewsFiveFamilies';
import NewsTelethon24 from './component/About/News/NewsTelethon24';

// RESEARCH 

import Education from './component/Research/Education';
import Research from './component/Research/Research';
import ResearchForm from './component/Research/ResearchForm';
import ResearchRequest from './component/Research/ResearchRequest';
import VisitResearch from './component/Research/VisitResearch';

// SUPPORT

import Membership from './component/Support/Membership';
import SupportUs from './component/Support/SupportUs';
import Volunteer from './component/Support/Volunteer';
import Telethon from './component/Support/Telethon';

// VISIT

import Events from './component/Visit/Events';
import Exhibits from './component/Visit/Exhibits';
import Programs from './component/Visit/Programs';
import Tours from './component/Visit/Tours';
import VisitUs from './component/Visit/VisitUs';

// PROGRAMS 

import ThreeHarriets from './component/Visit/Programs/ThreeHarriets';

import Market from './component/Visit/Programs/Market';
import StoryHourJuly from './component/Visit/Programs/StoryHourJuly';
import OpenHouseJuly from './component/Visit/Programs/OpenHouseJuly';
import ScavengerHunt from './component/Visit/Programs/ScavengerHunt';
import StoryHourAugust from './component/Visit/Programs/StoryHourAugust';
import OriskanyBattle from './component/Visit/Programs/OriskanyBattle';

import ReactGA from 'react-ga';

ReactGA.initialize('G-Q9KX2MMJHX');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Navbar/>
    <Routes>
        <Route path="" element={<App/>}>
          <Route index element={<Home/>}/>
          <Route path="*" element={<PageNotFound />} />
          <Route path="about" element={<About/>} />
          <Route path="contact" element={<Contact/>} />
          <Route path="news" element={<News/>} />
          <Route path="our-history" element={<OurHistory/>} />

          <Route path="news-1" element={<NewsPage1/>} />
          <Route path="news-2" element={<NewsPage2/>} />
          <Route path="news-3" element={<NewsPage3/>} />
          <Route path="news-ellen-luley" element={<NewsEllenLuley/>} />
          <Route path="news-five-families" element={<NewsFiveFamilies/>} />
          <Route path="news-telethon-2024" element={<NewsTelethon24/>} />

          {/* <Route path="education-and-resources" element={<Education/>} /> */}
          <Route path="research-and-learn" element={<Research/>} />
          <Route path="research-form" element={<ResearchForm/>} />
          <Route path="research-request" element={<ResearchRequest/>} />
          <Route path="visit-research-library" element={<VisitResearch/>} />

          <Route path="membership" element={<Membership/>} />
          <Route path="support-us" element={<SupportUs/>} />
          <Route path="volunteer" element={<Volunteer/>} />
          <Route path="telethon" element={<Telethon/>} />

          <Route path="events" element={<Events/>} />
          <Route path="exhibits" element={<Exhibits/>} />
          <Route path="programs-and-events" element={<Programs/>} />
          {/* <Route path="tours-and-rentals" element={<Tours/>} /> */}
          <Route path="visit" element={<VisitUs/>} />

        </Route>
      </Routes>
    <Footer/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
