import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const Market = () => {

    useEffect(() => {


        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Wed, July 10 | Utica</h5>
                    <h1>History Center Goes to the Market</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/market.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    New Hartford Farmers Market, Village Green on Oxford Road
                    <br/>Wednesday, July 10 from 2:30 - 6:30 p.m. 
                    </p>
                    <p className='fade-in-up'>
                    Oneida County Public Market, REA Wing Union Station
                    <br/>Saturdays, July 13, 20, & 27 from 9:00 a.m. – 1:00 p.m.
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Visit the History Center at the <a href="https://oneidacountypublicmarket.com/">[Oneida County Public Market]</a> and <a href="https://www.newhartfordchamber.com/farmers-market.html#!directory">[New Hartford Farmers Market]</a> this summer. Books, puzzles, and merchandise will be available for purchase. Shop local to support local history.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Market;