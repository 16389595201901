import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn'; 
import ReactGA from 'react-ga';

const Volunteer = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

        const carousel = document.querySelector(".carousel"),
        firstImg = carousel.querySelectorAll("img")[0],
        arrowIcons = document.querySelectorAll(".wrapper i");

        let isDragStart = false, isDragging = false, prevPageX, prevScrollLeft, positionDiff;

        const showHideIcons = () => {
            // showing and hiding prev/next icon according to carousel scroll left value
            let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
            arrowIcons[0].style.display = carousel.scrollLeft == 0 ? "none" : "block";
            arrowIcons[1].style.display = carousel.scrollLeft == scrollWidth ? "none" : "block";
        }

        arrowIcons.forEach(icon => {
            icon.addEventListener("click", () => {
                let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
                // if clicked icon is left, reduce width value from the carousel scroll left else add to it
                carousel.scrollLeft += icon.id == "left" ? -firstImgWidth : firstImgWidth;
                setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
            });
        });

        const autoSlide = () => {
            // if there is no image left to scroll then return from here
            if(carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) > -1 || carousel.scrollLeft <= 0) return;

            positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
            let firstImgWidth = firstImg.clientWidth + 14;
            // getting difference value that needs to add or reduce from carousel left to take middle img center
            let valDifference = firstImgWidth - positionDiff;

            if(carousel.scrollLeft > prevScrollLeft) { // if user is scrolling to the right
                return carousel.scrollLeft += positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
            }
            // if user is scrolling to the left
            carousel.scrollLeft -= positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
        }

        const dragStart = (e) => {
            // updating global variables value on mouse down event
            isDragStart = true;
            prevPageX = e.pageX || e.touches[0].pageX;
            prevScrollLeft = carousel.scrollLeft;
        }

        const dragging = (e) => {
            // scrolling images/carousel to left according to mouse pointer
            if(!isDragStart) return;
            e.preventDefault();
            isDragging = true;
            carousel.classList.add("dragging");
            positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
            carousel.scrollLeft = prevScrollLeft - positionDiff;
            showHideIcons();
        }

        const dragStop = () => {
            isDragStart = false;
            carousel.classList.remove("dragging");

            if(!isDragging) return;
            isDragging = false;
            autoSlide();
        }

        carousel.addEventListener("mousedown", dragStart);
        carousel.addEventListener("touchstart", dragStart);

        document.addEventListener("mousemove", dragging);
        carousel.addEventListener("touchmove", dragging);

        document.addEventListener("mouseup", dragStop);
        carousel.addEventListener("touchend", dragStop);


        const cards = document.querySelectorAll('.white-card');

        if (window.innerWidth > 900) {

            cards.forEach(function (card) {

                    const cardContent = card.querySelector(".card-content");
                    const expandButton = card.querySelector('.expand-button');
                    const whiteCardImg = card.querySelector('.white-card-image');
                
                    const cardTextsShorts = card.querySelector('.short-text');
                    const cardTextsFulls = card.querySelector('.full-text');
                
                    let isExpanded = false;
                
                    cardContent.style.display = "flex";
                    cardContent.style.height = "400px";
                    whiteCardImg.style.width = "600px";
                    whiteCardImg.style.height = "400px";
                
                    cardTextsFulls.style.display = "none";
                    cardTextsShorts.style.display = "block";
                
                    expandButton.addEventListener('click', function () {
                        // Your click event code for each button goes here
                        if (!isExpanded) {
                            // Expand the card
                            cardContent.style.display = "block";
                            cardContent.style.height = "100%";
                            whiteCardImg.style.width = "100%";
                            whiteCardImg.style.height = "520px";
                
                            cardTextsFulls.style.display = "block";
                            cardTextsShorts.style.display = "none";
                
                            expandButton.innerText = "CLOSE";
                            expandButton.style.float = 'right';
                            expandButton.style.marginBottom = "1em";
                        } else {
                            // Collapse the card
                            cardContent.style.display = "flex";
                            cardContent.style.height = "400px";
                            whiteCardImg.style.width = "600px";
                            whiteCardImg.style.height = "400px";
                
                            cardTextsFulls.style.display = "none";
                            cardTextsShorts.style.display = "block";
                
                            expandButton.innerText = "READ MORE";
                            expandButton.style.float = 'left';
                            expandButton.style.marginBottom = "0em";
                        }
                
                        isExpanded = !isExpanded; // Toggle the expanded state
                    });
            });

        } 
        else {

            // SHOW MOBILE

                const mobileCards = document.querySelectorAll('.mobile-card');
              
                mobileCards.forEach(function (mobileCard) {
                  const mobileContent = mobileCard.querySelector('.mobile-content');
                  const mobileReadMoreButton = mobileCard.querySelector('.mobile-read-more');
                  const mobileCloseButton = mobileCard.querySelector('.mobile-close');
                  const mobileFullText = mobileCard.querySelector('.mobile-full-text');
                  const mobileShortText = mobileCard.querySelector('.mobile-short-text');

                  mobileReadMoreButton.style.color = '#fff';
                  mobileCloseButton.style.color = '#fff';
                  
                  mobileReadMoreButton.style.width = '70%';
                  mobileCloseButton.style.width = '70%';
              
                  mobileReadMoreButton.addEventListener('click', function () {
                    mobileFullText.style.display = 'block';
                    mobileShortText.style.display = 'none';
                    mobileReadMoreButton.style.display = 'none';
                    mobileCloseButton.style.display = 'block';
                  });
              
                  mobileCloseButton.addEventListener('click', function () {
                    mobileFullText.style.display = 'none';
                    mobileShortText.style.display = 'block';
                    mobileReadMoreButton.style.display = 'block';
                    mobileCloseButton.style.display = 'none';
                  });
                });

        }

    }, []);



    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/support/volunteer-ochc.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Volunteer</h1>
                    <h4 className='fade-in-up-load'>
                    Volunteers are the heart of Oneida County History Center. Explore 
                    how you can play a crucial role in preserving our heritage and enriching our community.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2 className='fade-in-up'>Get Involved</h2>
                    <p className='fade-in-up'>
                    Volunteers are essential to the Oneida County History Center. They staff the bookstore, exhibit gallery, 
                    outreach tables, and research library assisting with a wide range of activities from answering phones to 
                    digitizing collections. All ages and backgrounds are encouraged to inquire. If you love history and are 
                    willing to share your time, then we'll find an opportunity to match your interests and skill set. Complete 
                    the volunteer application using the link below and check out our current opportunities.
                    </p>
                    <a href="https://drive.google.com/file/d/1wH9iCwfKyXvtDwmLx4xlq8Ou0OmoXHQe/view?usp=sharing"
                        className='main-button'
                        target="_blank"    
                    >
                        Volunteer Application
                    </a>
                    <br/><br/><br/><br/>
                    <h2 className='fade-in-up'>Why Volunteer?</h2>
                    <div class="row volunteer-row">
                        <div class="col-lg-4 volunteer-col">
                            <div className='card'>
                                <div className='volunteer-body'>
                                    <h3 className='fade-in-up'>Ellen L.</h3>
                                    <blockquote className='fade-in-up'>
                                    "I needed something to challenge my mind and I like to learn about history and genealogy."
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 volunteer-col middle-col">
                            <div className='card'>
                                <img decoding='async' loading='lazy' alt='alecia p volunteering' src="assets/TEMP-alecia.png" class="middle-image"/>
                                <div class="text-overlay">
                                    <div className='volunteer-body'>
                                        <h3 className='fade-in-up'>Alecia P.</h3>
                                        <blockquote className='fade-in-up'>
                                        "I volunteer because I enjoy learning new things about our community. Getting to contribute to preserving its history is a wonderful feeling. I've made great friends here and sincerely love coming every week."
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 volunteer-col">
                            <div className='card'>
                                <div className='volunteer-body'>
                                    <h3 className='fade-in-up'>Joan K.</h3>
                                    <blockquote className='fade-in-up'>
                                    "My favorite thing about volunteering is learning about the history of our area and working with people whose interests are similar."
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/><br/><br/>
                <div className='container'>
                    <h2 className='fade-in-up'>Volunteer Opportunities</h2>

                    <h5 className='fade-in-up'>Bookstore Assistant</h5>
                    <p className='fade-in-up'>
                    Volunteer to staff the History Center bookstore on Tuesday through Friday mornings. Greet visitors, checkout customers, and answer the phone. Basic computer/ smart phone skills necessary. Volunteer for one day a week or more!
                    </p>

                    <h5 className='fade-in-up'>Program Greeter</h5>
                    <p className='fade-in-up'>
                    The History Center hosts more than 40 programs a year! Join us to staff registration tables and greet our visitors.
                    </p>

                    <h5 className='fade-in-up'>Photo Assistant</h5>
                    <p className='fade-in-up'>
                    The History Center has over 30,000 images and we need help identifying many of our photos. Join us as a photo detective and help us solve our history mysteries. Those with a knowledge of the local area are strongly encouraged to inquire! Basic computer skills preferred, but not required.
                    </p>

                    <h5 className='fade-in-up'>Other Opportunities</h5>
                    <p className='fade-in-up'>
                    Custodial, administrative, and membership support. Contact the History Center at 315-735-3642 for more information.
                    </p>

                    <br/><br/><br/>
                    <div id="volunteer-card" className='white-card-body'>
                        <div class="card white-card">
                            <div class="card-content">
                                <div>
                                    <img decoding='async' loading='lazy' alt='saturday globe' class="white-card-image" src="assets/support/ellen_luley_volunteer.jpg"/>   
                                </div>
                                <div class="card-text">
                                    <h3 className='card-title'>Ellen Luley<br/>2023-24 Volunteer of the Year</h3>
                                    <div className='card-second-child short-text'>
                                        <p className='fade-in-up'>
                                        She “goes the extra mile” and has “a great work ethic and enthusiasm.” She “provides great customer service and engages every visitor.” This is how fellow volunteers and staff describe Ellen Luley, History Center volunteer of the year ...
                                        </p>
                                    </div>
                                    <div className='card-second-child full-text'>
                                        <p className='fade-in-up'>
                                        She “goes the extra mile” and has “a great work ethic and enthusiasm.” She “provides great customer service and engages every visitor.” This is how fellow volunteers and staff describe Ellen Luley, History Center volunteer of the year. Ellen was honored with this award during the annual membership meeting on December 12, 2023. She was chosen for her enthusiasm and dedication to our organization by her co-volunteers, staff, and board of directors. Congratulations and thank you to Ellen.
                                        </p>
                                        <p className='fade-in-up'>
                                        Ellen started volunteering in 2021 and has made a tremendous impact. Greeting visitors, decorating, hosting outreach booths, processing memberships, brainstorming scavenger hunt clues. She does it all and with a big smile. Her favorite part about volunteering is being involved in special events such as welcoming guests at the telethon and Hall of Fame. Her least favorite task is vacuuming but will happily help with anything and she doesn’t dislike anything she does to contribute.
                                        </p>
                                        <p className='fade-in-up'>
                                        Ellen volunteers to socialize and keep her mind active, and she finds volunteering rewarding because she learns new skills, which makes her feel accomplished. She said that it “gives me a sense of worth because the staff and volunteers are open to her ideas and value what she does. It has improved her self-confidence, and she has taken her new skills to other organizations.” She promotes the History Center wherever she goes and loves to share her new knowledge and ever-expanding enthusiasm with her family and friends.
                                        </p>
                                        <p className='fade-in-up'>
                                        Ellen is an active member of the community. She serves as board president of the DeSales Center and on the board of the Catholic Women’s Club. She is a member of numerous organizations including Oneida Chapter DAR, Christ Child Society, and Ladies Ancient Order of Hibernians. When she is not volunteering, Ellen enjoys quilting, puzzling, decorating, listening to books on tape, and traveling both around the US and internationally.
                                        </p>
                                        <p className='fade-in-up'>
                                        The History Center staff, volunteers, and board of directors would like to thank and recognize Ellen Luley for her exemplary service to the History Center and the community. She is one of more than two dozen volunteers who help local history thrive. These amazing people are essential to the daily operations and long-term success of the History Center. We are grateful for enduring and passionate volunteers like Ellen.
                                        </p>
                                    </div>
                                    <a className="main-button expand-button">Read More 
                                    </a>  
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div id="volunteer-card-mobile" class="card mobile-card">
                        <div>
                            <img decoding='async' loading='lazy' alt='saturday globe' class="white-card-image" src="assets/support/ellen_luley_volunteer.jpg"/>   
                        </div>
                        <div class="mobile-content">
                            <h3 className='card-title'>Ellen Luley<br/>2023-24 Volunteer of the Year</h3>
                            <div className='mobile-short-text'>
                                <p>
                                She “goes the extra mile” and has “a great work ethic and enthusiasm.” She “provides great customer service and engages every visitor.” This is how fellow volunteers and staff describe Ellen Luley, History Center volunteer of the year ...
                                </p>
                            </div>
                            <div className='mobile-full-text'>
                                <p>
                                She “goes the extra mile” and has “a great work ethic and enthusiasm.” She “provides great customer service and engages every visitor.” This is how fellow volunteers and staff describe Ellen Luley, History Center volunteer of the year. Ellen was honored with this award during the annual membership meeting on December 12, 2023. She was chosen for her enthusiasm and dedication to our organization by her co-volunteers, staff, and board of directors. Congratulations and thank you to Ellen.
                                </p>
                                <p>
                                Ellen started volunteering in 2021 and has made a tremendous impact. Greeting visitors, decorating, hosting outreach booths, processing memberships, brainstorming scavenger hunt clues. She does it all and with a big smile. Her favorite part about volunteering is being involved in special events such as welcoming guests at the telethon and Hall of Fame. Her least favorite task is vacuuming but will happily help with anything and she doesn’t dislike anything she does to contribute.
                                </p>
                                <p>
                                Ellen volunteers to socialize and keep her mind active, and she finds volunteering rewarding because she learns new skills, which makes her feel accomplished. She said that it “gives me a sense of worth because the staff and volunteers are open to her ideas and value what she does. It has improved her self-confidence, and she has taken her new skills to other organizations.” She promotes the History Center wherever she goes and loves to share her new knowledge and ever-expanding enthusiasm with her family and friends.
                                </p>
                                <p>
                                Ellen is an active member of the community. She serves as board president of the DeSales Center and on the board of the Catholic Women’s Club. She is a member of numerous organizations including Oneida Chapter DAR, Christ Child Society, and Ladies Ancient Order of Hibernians. When she is not volunteering, Ellen enjoys quilting, puzzling, decorating, listening to books on tape, and traveling both around the US and internationally.
                                </p>
                                <p>
                                The History Center staff, volunteers, and board of directors would like to thank and recognize Ellen Luley for her exemplary service to the History Center and the community. She is one of more than two dozen volunteers who help local history thrive. These amazing people are essential to the daily operations and long-term success of the History Center. We are grateful for enduring and passionate volunteers like Ellen.
                                </p>
                            </div>
                            <a class="main-button mobile-read-more">Read More</a>
                            <a class="main-button mobile-close">Close</a>
                        </div>
                    </div>
                    <br/><br/>
                    <h5 className='fade-in-up'>Student Internships</h5>
                    <p className='fade-in-up'>
                    Internships at the History Center are a great way to gain hands-on experience in the fields of history or museum studies. Internships are open to high school, undergraduate, and graduate students. Most internships are unpaid; however, check with your institution about course credit or service hours.
                    </p>


                    <div class="wrapper">
                        <i id="left" class="fa fa-arrow-left"></i>
                        <div class="carousel">
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol5.png" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol6.png" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol7.png" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol1.png" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol2.png" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol3.png" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol4.png" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol8.jpg" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol9.jpg" draggable="false"/>
                            <img decoding='async' loading='lazy' alt='volunteer helping out'  src="assets/vol10.jpg" draggable="false"/>
                        </div>
                        <i id="right" class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Volunteer;