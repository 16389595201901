import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsFiveFamilies = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/Five_Families_Band_Photo.jpeg" />
                    <div className='row'>
                        <div className='col-lg-9'>                    
                            <h2 className='fade-in-up'>Monk Rowe Awarded Grant for History Concerts</h2>
                        </div>
                        <div className='col-lg-3'>
                            <a href="/programs-and-events" 
                                target='_blank'
                                className='main-button'>
                                ALL PROGRAMS
                            </a>
                            <br/><br/>
                        </div>
                    </div>
                    <p className='fade-in-up'>
                    Monk Rowe & the Five Families Ensemble have partnered with Oneida County History Center to present two concerts in 2024. The Ensemble offers a unique instrumentation comprised of the four instrumental families (strings, woodwinds, brass, and percussion), plus the timbre of the human voice as the fifth family member. Music will be arranged and composed by Rowe and will include premiere performances. This project is made possible with funds from the Statewide Community Regrants Program, a regrant program of the New York State Council on the Arts with the support of the Office of the Governor and the New York State Legislature and administered by CNY Arts.
                    </p>
                    <p className='fade-in-up'>
                    The History Center is excited to collaborate with Rowe, who is a talented musician and one of our organization’s Living Legend recipients, a community service award given to those who have and are making an impact in our region and on its history. 
                    </p>
                    <p className='fade-in-up'>
                    The first concert, “The Sound of Freedom”, will be performed on February 2, 2024, and celebrates Black History Month. A complement to the History Center’s Underground Railroad exhibit, this program presents a musical journey focusing on this crucial time in American history. The second concert will be held in the fall and will explore the connection between music compositions, titles, and intent. Stay tuned for more details.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default NewsFiveFamilies;