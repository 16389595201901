import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const UticaPotteryJune = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Wed. June 12 | Utica</h5>
                    <h1>Utica Potteries (*Other than White’s Pottery)</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/uticapotteryjune.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Wednesday, June 12, 2024, 5:30 P.M.
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Whites Pottery of Utica was a famous producer of decorative and utilitarian objects that are now
                    highly sought-after antiques. The stoneware, which was made between 1832 and 1907, is
                    recognizable by its blue and grey color scheme and floral and bird motifs. Fred Capozzella, a
                    member of the Mohawk Valley Antique Bottle Club, will share his research on “the other”
                    potteries in Utica that are often overlooked. Capozzella used city directories, maps, and atlases
                    to uncover information about these potteries. During his presentation, he will discuss these
                    “other” potters and bring a few examples for attendees to see.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default UticaPotteryJune;