import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';


const NewsEllenLuley = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event' className="main-img" src="assets/support/Ellen_Luley.png"/>
                    <h2 className='fade-in-up'>Volunteer of the Year: Ellen Luley</h2>
                    <p className='fade-in-up'>
                    She “goes the extra mile” and has “a great work ethic and enthusiasm.” She “provides great customer service and engages every visitor.” This is how fellow volunteers and staff describe Ellen Luley, History Center volunteer of the year. Ellen was honored with this award during the annual membership meeting on December 12, 2023. She was chosen for her enthusiasm and dedication to our organization by her co-volunteers, staff, and board of directors. Congratulations and thank you to Ellen.
                    </p>
                    <p className='fade-in-up'>
                    Ellen started volunteering in 2021 and has made a tremendous impact. Greeting visitors, decorating, hosting outreach booths, processing memberships, brainstorming scavenger hunt clues. She does it all and with a big smile. Her favorite part about volunteering is being involved in special events such as welcoming guests at the telethon and Hall of Fame. Her least favorite task is vacuuming but will happily help with anything and she doesn’t dislike anything she does to contribute.
                    </p>
                    <p className='fade-in-up'>
                    Ellen volunteers to socialize and keep her mind active, and she finds volunteering rewarding because she learns new skills, which makes her feel accomplished. She said that it “gives me a sense of worth because the staff and volunteers are open to her ideas and value what she does. It has improved her self-confidence, and she has taken her new skills to other organizations.” She promotes the History Center wherever she goes and loves to share her new knowledge and ever-expanding enthusiasm with her family and friends.
                    </p>
                    <p className='fade-in-up'>
                    Ellen is an active member of the community. She serves as board president of the DeSales Center and on the board of the Catholic Women’s Club. She is a member of numerous organizations including Oneida Chapter DAR, Christ Child Society, and Ladies Ancient Order of Hibernians. When she is not volunteering, Ellen enjoys quilting, puzzling, decorating, listening to books on tape, and traveling both around the US and internationally.
                    </p>
                    <p className='fade-in-up'>
                    The History Center staff, volunteers, and board of directors would like to thank and recognize Ellen Luley for her exemplary service to the History Center and the community. She is one of more than two dozen volunteers who help local history thrive. These amazing people are essential to the daily operations and long-term success of the History Center. We are grateful for enduring and passionate volunteers like Ellen.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default NewsEllenLuley;