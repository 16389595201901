import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const SallyRoeschWagner = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. Dec 7 | Utica</h5>
                    <h1>Lecture: Women Voted on This Land Before Columbus </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/Sally_Roesch_Wagner.png"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, December 7, 2:00 P.M.
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    What inspired the suffragists to think they could create a world where women could be agents of their own being? Renowned women’s history scholar Dr. ﻿Sally Roesch Wagner will share how the surprising answer may lie with the sovereign women of the Haudenosaunee, or Iroquois Confederacy of six nations (Mohawk, Oneida, Onondaga, Cayuga, Seneca, and Tuscarora) in upstate New York, who showed their settler neighbors how a society that empowered women worked. This lecture is free and open to the public, but donations are encouraged.
                    </p>
                    <p>
                    This lecture is part of the humanities discussion series, "A New Agora for New York: Museums as Spaces for Democracy," that includes the Smithsonian's traveling exhibition, <i>Voices and Votes: Democracy in America</i> currently on view at <a target="_blank" href="https://www.munson.art/voices-and-votes">Munson</a> in the Museum of Art Root Court in downtown Utica, and <a target="_blank" href='https://oneidacountyhistory.org/exhibits'>An Essential Feature of Democracy: Lucy Carlile Watson</a>, on view at the History Center until Dec 13th.
                    </p>
                    <p>
                    Wagner is a major historian of the suffrage movement and the founding director of the Matilda Joslyn Gage Foundation. She was awarded one of the first doctorates in the country for work in women’s studies at the University of California Santa Cruz, and she has appeared on CNN, several PBS documentaries, and BBC. Her articles have appeared in numerous publications including Ms. Magazine, USA Today, and Northeast Indian Quarterly, and her books include The Women’s Suffrage Movement(Penguin Classics), and We Want Equal Rights: How Suffragists Were Influenced by Native American Women” (7th Generation). Wagner has taught women’s studies courses for 53 years, currently in Syracuse University’s Honors Program.
                    </p>
                    <p>
                    Voices and Votes is a Museum on Main Street (MoMS) exhibition developed by the Smithsonian Institution Traveling Exhibition Service. Based on an exhibition by the National Museum of American History, this touring exhibition has been made possible in New York State by the Museum Association of New York. Support for MoMS in New York State has been provided by the United States Congress and the William G. Pomeroy Foundation. “A New Agora for New York: Museums as Spaces for Democracy” humanities discussion programs are made possible in part by a major grant from the National Endowment for the Humanities.
                    </p>
                    <div className='row'>
                        <div className='col'>
                            <a href='https://agoranewyork.org' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/agora.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://www.si.edu' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/smithsonian.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://nysmuseums.org' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/museumny.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://www.neh.gov' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/humanities.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://www.wgpfoundation.org' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/pomeroy.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://www.sites.si.edu/s/topic/0TO1Q000000cTOZWA2/voices-and-votes-democracy-in-america' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/voicesvotes.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://humanitiesny.org' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/humanitiesny.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://www.nysm.nysed.gov' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/nymuseum.jpg'/>
                            </a>
                        </div>
                        <div className='col'>
                            <a href='https://www.ourstorybridge.org' target="_blank">
                                <img src='assets/visit/programs/Agora_Array/bridge.jpg'/>
                            </a>
                        </div>
                    </div>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default SallyRoeschWagner;