import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';

const PageNotFound = () => {

    return ( 
        <div>
            <Helmet>
                <title>404: Page Not Found</title>
                <meta name="description" 
                      content="Una Web Solutions is a web design 
                            company started to empower small businesses and 
                            nonprofits."
                />
            </Helmet>
            <section className='pagenotfound-section'>
                <div className='pagenotfound-img'>
                    <img src='assets/404.png'/>
                </div>
                <h1>Oops!</h1>
                <h2>Sorry. We couldn't find the page you're looking for.</h2>
                <h2>Please check the URL for any typos.</h2>
                <a className="main-button" href="/">Go Back Home</a>
            </section>

        </div>
    );
}

export default PageNotFound;