import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn'; 
import { LoadFadeIn } from '../LoadFadeIn'; 
import JotformEmbed from 'react-jotform-embed';
import ReactGA from 'react-ga';

const ResearchForm = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="research-request-page request-form">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src="assets/research/ochc-lib.webp" alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Research Request Form</h1>
                </div>            
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <div>
                        <h2>Thank you for your payment!</h2>
                        <p>Your transaction has been completed, and a receipt for your purchase has been emailed to you. </p>
                        <p>Log into your PayPal account to view transaction details.</p>
                    </div>
                    <p>Return your completed form, accompanied by the $25.00 research fee, to the Oneida
                    County History Center using the methods below.
                    by mail: 1608 Genesee Street, Utica, New York 13502-5425
                    e-mail: research@oneidacountyhistory.org</p>
                    <br/><br/>
                    <h2>Research Request Form</h2>
                    <br/>
                    <form className="contact-form" 
    name="Research_Request" 
    method="POST" 
    data-netlify="true" 
    netlify-honeypot="bot-field"
    id="request"
    >
    <div>  
        <input type="hidden" name="form-name" value="Research_Request" />
        <input type="hidden" name="subject" 
        value="Research Request from Website" />
        <div className="form-group">
            <label className='form-label'>Full Name</label>
            <input
                type="text"
                className="form-control"
                id="Request_first_name"
                name="Request_First_Name"
            />
            <br/>
            <label className='form-label'>Street Address</label>
            <input
                type="text"
                className="form-control"
                id="Request_address"
                name="Request_Address"
            /> 
            <br/>
            <div className='row'>
                <div className='col-lg-4'>
                    <label className='form-label'>City</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Request_city"
                        name="Request_City"
                    /> 
                </div>
                <div className='col-lg-4'>
                    <label className='form-label'>State</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Request_state"
                        name="Request_State"
                    /> 
                </div>
                <div className='col-lg-4'>
                    <label className='form-label'>ZIP</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Request_zip"
                        name="Request_ZIP"
                    /> 
                </div>
            </div>
            <br/>
            <label className='form-label'>Email</label>
            <input
                type="text"
                className="form-control"
                id="Request_email"
                name="Request_Email"
            /> 
            <br/>
            <label className='form-label'>Phone Number</label>
            <input 
                type="tel" 
                className="form-control"
                id="Request_phone" 
                name="Request_Phone"
            />
            <br/>
            <label className='form-label'>Subject</label>
            <input 
                type="tel" 
                className="form-control"
                id="Request_subject" 
                name="Request_Subject"
            />
            <br/>
            <label className='form-label'>Type of Request</label>
            <input 
                type="tel" 
                className="form-control"
                id="Request_Type" 
                name="Request_Type"
                placeholder='Enter Genealogy and/or General History'
            />
            <br/>
        </div>                                                                               
    </div>
    <div>
        <label className='form-label'>Research Question: A brief, specific statement about what you would like to learn.</label>
        <textarea
            className="form-control"
            id="Research_Question"
            rows="8"
            name="Research_Question"
        ></textarea>
    </div>
    <br/>
    <div>
        <label className='form-label'>Additional Comments & Details for Research Request:</label>
        <textarea
            className="form-control"
            id="Additional_Comments"
            rows="12"
            name="Additional_Comments"
        ></textarea>
    </div>
    <br/>
    <button type="submit" id="request-submit-btn" className="main-button">Submit</button> 
</form>
                </div>
            </section>
        </div>
    );
}

export default ResearchForm;