import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsPage1 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event' className="main-img" src="assets/about/news/ochc-dino.png"/>
                    <h2 className='fade-in-up'>Dinosaur Technologies</h2>
                    <p>
                    The Gannett Gallery and Oneida County History Center present Dinosaur Technologies. This exhibit at the Gannett Gallery at SUNY Poly will showcase "old school" technologies, some of which were invented right here in the Mohawk Valley. 
                    <br/> <br/>Come and see if you can guess our mystery object!  You might be surprised!
                    <br/><br/> Location: Gannett Gallery, Kunsela Hall, SUNY Polytechnic Institute
                                100 Seymour Road, Utica, N.Y. 13502
                                <br/>Hours:M-F from 9 a.m.- 5 p.m.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default NewsPage1;