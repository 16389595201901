import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const OpenHouseJuly = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat, July 13 | Utica</h5>
                    <h1>Proctor Memorial Building Open House                    </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/openhouse_july.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, July 13 from 10:00 a.m. - 2:00 p.m. 
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Many people believe the little stone building under the Genesee Street bridge is Bagg’s Hotel, but it’s not! Maria Proctor erected this building to memorialize her husband, Thomas R. Proctor, and their beloved Bagg’s Hotel. Join the History Center for an exclusive tour of the Proctor Memorial Building at Bagg Commemorative Park plus a slideshow featuring photos of early downtown Utica.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default OpenHouseJuly;