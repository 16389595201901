import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../LoadFadeIn';
import { FadeIn } from '../FadeIn'; 
import Calendar from "@ericz1803/react-google-calendar";
import { css } from "@emotion/react";
import ReactGA from 'react-ga';


const Events = () => {

    useEffect(() => {


        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();


    }, []);

    const API_KEY = "AIzaSyBpwU-49i7Cu5P8wm7t2XHChHyEbSmxGLE";
    let calendars = [
        { 
            calendarId: "c_sd5j1iltoh0aakrq0gs011qtjs@group.calendar.google.com",
            color: '#004D72'
        }
        
    ];

   let styles = {
        //you can use object styles (no import required)
        event: {
          borderWidth: "3px", //make outer edge of calendar thicker
        },

        tooltip: {
            opacity: "1",
            padding: "20px",
        }
      };

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/visit/calendar/OCHC-Fall.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Upcoming events</h1>
                    <h4 className='fade-in-up-load'>
                    Stay up-to-date with all the past and upcoming events and exhibits with our calendar below.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='calendar'>
                    <Calendar apiKey={API_KEY} calendars={calendars} styles={styles}/>
                </div>
            </section>
        </div>
    );
}

export default Events;