import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsPage2 = () => {

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news-event' className="main-img"src="assets/about/news/MVGives_Mclain.jpg"/>
                    <h2 className='fade-in-up'>Save the Date!</h2>
                    <p>
                    Help us raise $3,000 to restore our doors! Show your support for local history during Mohawk Valley Gives on Wednesday, September 20, 2023. The front doors at 1608 Genesee need to be refinished to protect and preserve this historical, original feature of the building.
                    <br/>A generous grant from Oneida County will cover most of the project, but we need your help to raise the remaining funds. As a not-for-profit that depends on contributions, we appreciate gifts of any size! 
                    </p>
                </div>
            </section>
        </div>
    );
}

export default NewsPage2;