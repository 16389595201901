import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';

const Contact = () => {

    useEffect(() => {

        LoadFadeIn();

        ReactGA.pageview(window.location.pathname + window.location.search);


    }, []);

    return (
        <div id="contact-page">
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/about/contact/TEMP-contact-header.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Contact Us</h1>
                    <h4  className='fade-in-up-load'>
                    We're here to answer any of your questions and explore the history of Oneida County together.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='contact-row row'>
                    <div className='col-lg-5 row-1'>
                        <div className='row'>
                            <div className='col-3'>
                                <div>
                                    <img src="assets/TEMP-mail.png" alt="icon" width="80" height="80" aria-hidden="true" decoding="async"/>
                                </div>
                            </div>
                            <div className='col-9'>
                                <h4>Email Us:</h4>
                                <p><a href="mailto:ochc@oneidacountyhistory.org">ochc@oneidacountyhistory.org</a></p>
                                <h4>Research Requests:</h4>
                                <p><a href="mailto:research@oneidacountyhistory.org">research@oneidacountyhistory.org</a></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 row-2'>
                        <div className='row'>
                            <div className='col-4'>
                                <div>
                                    <img src="assets/TEMP-phone.png" alt="icon" width="80" height="80" aria-hidden="true" decoding="async"/>
                                </div>
                            </div>
                            <div className='col-8'>
                                <h4>Call Us:</h4>
                                <p><a href="tel:(315) 735-3642">(315) 735-3642</a></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 row-3'>
                        <div className='row'>
                            <div className='col-3'>
                                <div>
                                    <img src="assets/TEMP-clock.png" alt="icon" width="80" height="80" aria-hidden="true"  decoding='async'/>
                                </div>
                            </div>
                            <div className='col-9'>
                                <h4>Hours of Operation:</h4>
                                <p>Tuesday - Saturday<br/> 
                                10:00am - 4:00pm</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container map-row row">
                    <div className='map-row-text col-lg-6'>
                        <form className="contact-form w-100" 
                            name="Contact" 
                            method="POST" 
                            data-netlify="true" 
                            netlify-honeypot="bot-field"
                            id="contact"
                            >
                            <div>  
                                <input type="hidden" name="form-name" value="Contact" />
                                <input type="hidden" name="subject" 
                                value="Form Submission from Website" />
                                <div className="form-group">
                                            <label className='form-label'>Full Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="first_name"
                                                name="First_name"
                                            />
                                            <br/>
                                    <label className='form-label'>Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        name="Email"
                                    /> 
                                    <br/>
                                    <label className='form-label'>Phone Number</label>
                                    <input 
                                        type="tel" 
                                        className="form-control"
                                        id="phone" 
                                        name="Phone"
                                    />
                                </div>                                                                               
                            </div>
                            <div>
                                <label className='form-label'>Message</label>
                                <textarea
                                    className="form-control"
                                    id="FormControlTextarea"
                                    rows="10"
                                    name="Message"
                                ></textarea>
                            </div>
                            <br/>
                            <button type="submit" id="contact-us-submit-btn" className="main-button">Submit</button> 
                        </form>
                    </div>
                    <div className='col-lg-6'>
                        <div className='google-map'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11654.480941238513!2d-75.2523385!3d43.0914788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9412dc1632acf%3A0xcaba271eb9a1d0b0!2sOneida%20County%20History%20Center!5e0!3m2!1sen!2sus!4v1694956871181!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;