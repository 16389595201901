import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const MothersdaySipandPaint = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. May 11 | Utica</h5>
                    <h1>Mother's Day Sip and Paint</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/mothersday.png"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, May 11, 2024, 1:00 P.M. - 3:00 P.M.
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Oneida County History Center is bringing back its popular Sip & Paint for Mother’s Day weekend. Participants will paint a landscape featuring the picturesque Verona Beach lighthouse from Oneida Lake. Tickets are $40 for non-members and $35 for History Center members and include all art materials, one beverage ticket, and light snacks. Pre-registration is required. Join us for an afternoon of a bit of painting, a little sipping, and a lot of fun!
                    </p>
                    <p>
                    Attendees will be led by the instructors from Painting Wild and will create their version 
                    of the featured painting while sipping and enjoying the company of other painters. Drinks will include wine and non-alcoholic options. Anyone planning to consume alcoholic beverages should come prepared to show a valid ID. 
                    </p>
                    <p>
                    To purchase tickets, <a target="_blank" href="https://oneidacountyhistory.square.site/s/search?q=Sip">follow this link</a>. 
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default MothersdaySipandPaint;