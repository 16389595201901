import React from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';

const Exhibits = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();
                
        const cards = document.querySelectorAll('.white-card');

        if (window.innerWidth > 900) {

            cards.forEach(function (card) {

                    const cardContent = card.querySelector(".card-content");
                    const expandButton = card.querySelector('.expand-button');
                    const whiteCardImg = card.querySelector('.white-card-image');
                
                    const cardTextsShorts = card.querySelector('.short-text');
                    const cardTextsFulls = card.querySelector('.full-text');
                
                    let isExpanded = false;
                
                    cardContent.style.display = "flex";
                    cardContent.style.height = "400px";
                    whiteCardImg.style.width = "600px";
                    whiteCardImg.style.height = "400px";
                
                    cardTextsFulls.style.display = "none";
                    cardTextsShorts.style.display = "block";
                
                    expandButton.addEventListener('click', function () {
                        // Your click event code for each button goes here
                        if (!isExpanded) {
                            // Expand the card
                            cardContent.style.display = "block";
                            cardContent.style.height = "100%";
                            whiteCardImg.style.width = "100%";
                            whiteCardImg.style.height = "520px";
                
                            cardTextsFulls.style.display = "block";
                            cardTextsShorts.style.display = "none";
                
                            expandButton.innerText = "CLOSE";
                            expandButton.style.float = 'right';
                            expandButton.style.marginBottom = "1em";
                        } else {
                            // Collapse the card
                            cardContent.style.display = "flex";
                            cardContent.style.height = "400px";
                            whiteCardImg.style.width = "600px";
                            whiteCardImg.style.height = "400px";
                
                            cardTextsFulls.style.display = "none";
                            cardTextsShorts.style.display = "block";
                
                            expandButton.innerText = "READ MORE";
                            expandButton.style.float = 'left';
                            expandButton.style.marginBottom = "0em";
                        }
                
                        isExpanded = !isExpanded; // Toggle the expanded state
                    });
            });

        } 
        else {

            // SHOW MOBILE

                const mobileCards = document.querySelectorAll('.mobile-card');
              
                mobileCards.forEach(function (mobileCard) {
                  const mobileContent = mobileCard.querySelector('.mobile-content');
                  const mobileReadMoreButton = mobileCard.querySelector('.mobile-read-more');
                  const mobileCloseButton = mobileCard.querySelector('.mobile-close');
                  const mobileFullText = mobileCard.querySelector('.mobile-full-text');
                  const mobileShortText = mobileCard.querySelector('.mobile-short-text');

                  mobileReadMoreButton.style.color = '#fff';
                  mobileCloseButton.style.color = '#fff';
                  
                  mobileReadMoreButton.style.width = '70%';
                  mobileCloseButton.style.width = '70%';
              
                  mobileReadMoreButton.addEventListener('click', function () {
                    mobileFullText.style.display = 'block';
                    mobileShortText.style.display = 'none';
                    mobileReadMoreButton.style.display = 'none';
                    mobileCloseButton.style.display = 'block';
                  });
              
                  mobileCloseButton.addEventListener('click', function () {
                    mobileFullText.style.display = 'none';
                    mobileShortText.style.display = 'block';
                    mobileReadMoreButton.style.display = 'block';
                    mobileCloseButton.style.display = 'none';
                  });
                });

        }
                
    }, []);

    return (
        <div id="exhibits-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/visit/exhibits/exhibit-header.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Special Exhibits</h1>
                    <h4 className='fade-in-up-load'>
                    Our 5,000 square-foot gallery has multiple exhibits running concurrently. 
                    Volunteers, town historians, interns, and community organizations help 
                    bring you a slice of history with a local perspective.
                    </h4>
                </div> 
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <p className='fade-in-up'>
                    Pay what you wish admission ($5 suggested donation); free for members.
                    </p>
                    <br/>
                    <div className='white-card-body'>
                        <div class="card white-card">
                            <div class="card-content">
                                <div>
                                    <img decoding='async' loading='lazy' alt='Lucy Watson exhibit' class="white-card-image" src="assets/visit/exhibits/lucywatson.jpg"/>
                                </div>
                                <div class="card-text">
                                    <h3 className='card-title'>An Essential Feature of Democracy: Lucy Carlile Watson</h3>
                                    <div className='card-second-child short-text'>
                                        <p className='fade-in-up'>
                                        Lucy Carlile Watson was born in Utica in 1855. She dedicated her adult life to community service and women’s suffrage causes. This special exhibit at ...
                                        </p>
                                    </div>
                                    <div className='card-second-child full-text'>
                                        <p className='fade-in-up'>
                                        <strong>Open November 1 - December 13, 2024</strong>
                                        </p>
                                        <p className='fade-in-up'>
                                        Lucy Carlile Watson was born in Utica in 1855. She dedicated her adult life to community service and women’s suffrage causes. This special exhibit at the History Center showcases Watson’s life and the contributions of local women in achieving equality and enhancing the lives of those in their community.
                                        </p>
                                        <p className='fade-in-up'>
                                        This exhibition is a companion to Voices and Votes: Democracy in America on display at MUNSON from Nov. 1 and to Dec. 13. Voices and Votes is a Museum on Main Street (MoMS) exhibition developed by the Smithsonian Institution Traveling Exhibition Service. It’s based on an exhibition by the National Museum of American History. It has been made possible in New York State by the Museum Association of New York. Support for MoMS in New York State has been provided by the United States Congress and the William G. Pomeroy Foundation. A New Agora for New York: Museums as Spaces for Democracy” humanities discussion programs are made possible in part by a major grant from the National Endowment for the Humanities.
                                        </p>
                                        <img decoding='async' loading='lazy' alt='Lucy Watson exhibit' class="full-exhibit-img fade-in-up" src="assets/visit/exhibits/agora.png"/>
                                        <br/><br/>
                                    </div>
                                    <a className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mobile-card">
                        <div>
                            <img decoding='async' loading='lazy' alt='progress of cotton exhibit' class="white-card-image" src="assets/visit/exhibits/lucywatson.jpg"/>
                        </div>
                        <div class="mobile-content">
                            <h3 className='card-title'>An Essential Feature of Democracy: Lucy Carlile Watson</h3>
                            <div className='mobile-short-text'>
                                <p>
                                Open November 1 - December 13, 2024
                                </p>
                                <p>
                                Lucy Carlile Watson was born in Utica in 1855. She dedicated her adult life to community service and women’s suffrage causes. This special exhibit at ...
                                </p>
                            </div>
                            <div className='mobile-full-text'>
                                <p>
                                Open November 1 - December 13, 2024
                                </p>
                                <p>
                                Lucy Carlile Watson was born in Utica in 1855. She dedicated her adult life to community service and women’s suffrage causes. This special exhibit at the History Center showcases Watson’s life and the contributions of local women in achieving equality and enhancing the lives of those in their community.
                                </p>
                                <p>
                                This exhibition is a companion to Voices and Votes: Democracy in America on display at MUNSON from Nov. 1 and to Dec. 13. Voices and Votes is a Museum on Main Street (MoMS) exhibition developed by the Smithsonian Institution Traveling Exhibition Service. It’s based on an exhibition by the National Museum of American History. It has been made possible in New York State by the Museum Association of New York. Support for MoMS in New York State has been provided by the United States Congress and the William G. Pomeroy Foundation. A New Agora for New York: Museums as Spaces for Democracy” humanities discussion programs are made possible in part by a major grant from the National Endowment for the Humanities.
                                </p>
                                <img decoding='async' loading='lazy' alt='Lucy Watson exhibit' class="full-exhibit-img" src="assets/visit/exhibits/agora.png"/>
                            </div>
                            <a class="main-button mobile-read-more">Read More</a>
                            <a class="main-button mobile-close">Close</a>   
                        </div>
                    </div>
                    <div className='white-card-body'>
                        <div class="card white-card">
                            <div class="card-content">
                                <div>
                                    <img decoding='async' loading='lazy' alt='Lucy Watson exhibit' class="white-card-image" src="assets/visit/programs/nutcrackernov.png"/>
                                </div>
                                <div class="card-text">
                                    <h3 className='card-title'>Not So Tiny Nutcracker Display</h3>
                                    <div className='card-second-child short-text'>
                                        <p className='fade-in-up'>
                                            <strong>November 30, 2024 - January 11, 2025</strong>
                                        </p>
                                        <p className='fade-in-up'>
                                        Discover the magic of the holiday season with our captivating Nutcracker exhibit. The display features over 600 nutcrackers from Joanne Gerace, the former owner ...
                                        </p>
                                    </div>
                                    <div className='card-second-child full-text'>
                                        <p className='fade-in-up'>
                                            <strong>November 30, 2024 - January 11, 2025</strong>
                                        </p>
                                        <p className='fade-in-up'>
                                        Discover the magic of the holiday season with our captivating Nutcracker exhibit. The display features over 600 nutcrackers from Joanne Gerace, the former owner of Tiny’s Grill. She has been collecting nutcrackers since 1993 and has loaned her collection to continue this unique Utica tradition. Experience the joy and enchantment of this cherished holiday tradition.
                                        </p>
                                        <br/><br/>
                                    </div>
                                    <a className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mobile-card">
                        <div>
                            <img decoding='async' loading='lazy' alt='progress of cotton exhibit' class="white-card-image" src="assets/visit/programs/nutcrackernov.png"/>
                        </div>
                        <div class="mobile-content">
                            <h3 className='card-title'>Not So Tiny Nutcracker Display</h3>
                            <div className='mobile-short-text'>
                                <p className='fade-in-up'>
                                    <strong>November 30, 2024 - January 11, 2025</strong>
                                </p>
                                <p className='fade-in-up'>
                                Discover the magic of the holiday season with our captivating Nutcracker exhibit. The display features over 600 nutcrackers from Joanne Gerace, the former owner ...
                                </p>
                            </div>
                            <div className='mobile-full-text'>
                                <p className='fade-in-up'>
                                    <strong>November 30, 2024 - January 11, 2025</strong>
                                </p>
                                <p className='fade-in-up'>
                                Discover the magic of the holiday season with our captivating Nutcracker exhibit. The display features over 600 nutcrackers from Joanne Gerace, the former owner of Tiny’s Grill. She has been collecting nutcrackers since 1993 and has loaned her collection to continue this unique Utica tradition. Experience the joy and enchantment of this cherished holiday tradition.
                                </p>                            
                            </div>
                            <a class="main-button mobile-read-more">Read More</a>
                            <a class="main-button mobile-close">Close</a>   
                        </div>
                    </div>
                    <div className='white-card-body'>
                        <div class="card white-card">
                            <div class="card-content">
                                <div>
                                    <img decoding='async' loading='lazy' alt='progress of cotton exhibit' class="white-card-image" src="assets/farming-exhibit.png"/>
                                </div>
                                <div class="card-text">
                                    <h3 className='card-title'>Farming Oneida County</h3>
                                    <div className='card-second-child short-text'>
                                        <p className='fade-in-up'>
                                        Agriculture has always been a leading industry in the Mohawk Valley. Farming trends and influential individuals have contributed to the growth and development of farming in Oneida...
                                        </p>
                                    </div>
                                    <div className='card-second-child full-text'>
                                        <p className='fade-in-up'>
                                        Agriculture has always been a leading industry in the Mohawk Valley. Farming trends and influential individuals have contributed to the growth and development of farming in Oneida County. From cows and cheese to hops and potatoes, farming has evolved and adapted to changes in the community. This new exhibit shares the udderly amazing story of the area's rich agricultural history.
                                        </p>
                                    </div>
                                    <a className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mobile-card">
                        <div>
                            <img decoding='async' loading='lazy' alt='progress of cotton exhibit' class="white-card-image" src="assets/farming-exhibit.png"/>
                        </div>
                        <div class="mobile-content">
                            <h3 className='card-title'>Farming Oneida County</h3>
                            <div className='mobile-short-text'>
                                <p>
                                Agriculture has always been a leading industry in the Mohawk Valley. Farming trends and influential individuals have contributed to the growth and development of farming in Oneida...
                                </p>
                            </div>
                            <div className='mobile-full-text'>
                                <p>
                                Agriculture has always been a leading industry in the Mohawk Valley. Farming trends and influential individuals have contributed to the growth and development of farming in Oneida County. From cows and cheese to hops and potatoes, farming has evolved and adapted to changes in the community. This new exhibit shares the udderly amazing story of the area's rich agricultural history.
                                </p>
                            </div>
                            <a class="main-button mobile-read-more">Read More</a>
                            <a class="main-button mobile-close">Close</a>   
                        </div>
                    </div>
                    <div className='white-card-body'>
                        <div class="card white-card">
                            <div class="card-content">
                                <div>
                                    <img decoding='async' loading='lazy' alt='saturday globe' class="white-card-image" src="assets/visit/exhibits/GlobeHorizontalAdvert.webp"/>
                                </div>
                                <div class="card-text">
                                    <h3 className='card-title'>Saturday Globe: National News in Color</h3>
                                    <div className='card-second-child short-text'>
                                        <p className='fade-in-up'>
                                        The Saturday Globe redefined the traditional newspaper and expanded regional journalism through innovative color printing and distribution methods.                                    
                                        </p>
                                    </div>
                                    <div className='card-second-child full-text'>
                                        <p className='fade-in-up'>
                                        The Saturday Globe redefined the traditional newspaper and expanded regional journalism through innovative color printing and distribution methods.
                                        </p>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <img className='history-card-img' src="assets/visit/exhibits/ochc-sat.webp"/>   
                                            </div>
                                            <div className='col-lg-6'>
                                                <img className='history-card-img' src="assets/visit/exhibits/ochc-sat2.webp"/>   
                                            </div>
                                        </div>
                                        <p className='fade-in-up'>
                                        Printed locally in Utica from 1881 to 1924, the GLOBE shipped to 40 different editions to US cities and every corner of the world.  It was a pioneer in the news industry, perfecting the printing of photographs which brought readers the stories of the world in color. 
                                        </p>
                                        <p className='fade-in-up'>
                                        Don't miss the chance to view this one-of-a-kind collection.  
                                        </p>
                                    </div>
                                    <a className="main-button expand-button">Read More 
                                    </a>  
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="card mobile-card">
                        <div>
                            <img decoding='async' loading='lazy' alt='saturday globe' class="white-card-image" src="assets/visit/exhibits/GlobeHorizontalAdvert.webp"/>
                        </div>
                        <div class="mobile-content">
                            <h3 className='card-title'>Saturday Globe: National News in Color</h3>
                            <div className='mobile-short-text'>
                                <p>
                                The Saturday Globe redefined the traditional newspaper and expanded regional journalism through innovative color printing and distribution methods.                                    
                                </p>
                            </div>
                            <div className='mobile-full-text'>
                                <p>
                                The Saturday Globe redefined the traditional newspaper and expanded regional journalism through innovative color printing and distribution methods.
                                </p>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <img className='history-card-img' src="assets/visit/exhibits/ochc-sat.webp"/>   
                                    </div>
                                    <div className='col-lg-6'>
                                        <img className='history-card-img' src="assets/visit/exhibits/ochc-sat2.webp"/>   
                                    </div>
                                </div>
                                <p>
                                Printed locally in Utica from 1881 to 1924, the GLOBE shipped to 40 different editions to US cities and every corner of the world.  It was a pioneer in the news industry, perfecting the printing of photographs which brought readers the stories of the world in color. 
                                </p>
                                <p>
                                Don't miss the chance to view this one-of-a-kind collection.  
                                </p>
                            </div>
                            <a class="main-button mobile-read-more">Read More</a>
                            <a class="main-button mobile-close">Close</a>
                        </div>
                    </div>
                    <div className='white-card-body'>
                        <div class="card white-card">
                            <div class="card-content">
                                <div>
                                    <iframe class="white-card-image" src="https://www.youtube.com/embed/m3SvbS0aI9I?si=B4Nc8Y9YURlv9xba" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div class="card-text">
                                    <h3 className='card-title'>Central New York & The Birth of the Oneida County Freedom Trail</h3>
                                    <div className='card-second-child short-text'>
                                        <p className='fade-in-up'>
                                        The Underground Railroad was more than a route to freedom; it was a social movement that blurred the lines of race and gender.                                    
                                        </p>
                                    </div>
                                    <div className='card-second-child full-text'>
                                        <p className='fade-in-up'>
                                        The Underground Railroad was more than a route to freedom; it was a social movement that blurred the lines of race and gender.                                    
                                        </p>
                                        <p className='fade-in-up'>
                                        It featured the cooperation of free and enslaved groups -- black, white, men, and women who pushed the nation one step closer to universal freedom.
                                        </p>
                                        <p className='fade-in-up'>
                                        This exhibit highlights the Underground Railroad movement in Oneida County along with how it weaved together social, political, and economic transformations, which made the route to freedom possible.
                                        </p>
                                        <h5 className='fade-in-up'>Walking Tour Exhibit Companion</h5>
                                        <p className='fade-in-up'>
                                        This self-guided narrated tour provides maps and images of nine stops in downtown Utica associated with the Underground Railroad. Developed in partnership with the 
                                        Oneida County Freedom Trail Commission and is based on tours previously given by the Commission. 
                                        Click the map below to begin your tour.
                                        </p>
                                        <a target="_blank" href="https://theclio.com/tour/2012">
                                            <img decoding='async' loading='lazy'  src="assets/TEMP-trail.png"/>
                                        </a>
                                    </div>
                                    <a className="main-button expand-button">Read More 
                                    </a>  
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="card mobile-card">
                        <div>
                            <iframe class="white-card-image" src="https://www.youtube.com/embed/m3SvbS0aI9I?si=B4Nc8Y9YURlv9xba" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div class="mobile-content">
                            <h3 className='card-title'>Central New York & The Birth of the Oneida County Freedom Trail</h3>
                            <div className='mobile-short-text'>
                                <p>
                                The Underground Railroad was more than a route to freedom; it was a social movement that blurred the lines of race and gender.                                    
                                </p>
                            </div>
                            <div className='mobile-full-text'>
                                <p>
                                The Underground Railroad was more than a route to freedom; it was a social movement that blurred the lines of race and gender.                                    
                                </p>
                                <p>
                                It featured the cooperation of free and enslaved groups -- black, white, men, and women who pushed the nation one step closer to universal freedom.
                                </p>
                                <p>
                                This exhibit highlights the Underground Railroad movement in Oneida County along with how it weaved together social, political, and economic transformations, which made the route to freedom possible.
                                </p>
                                <h5>Walking Tour Exhibit Companion</h5>
                                <p>
                                This self-guided narrated tour provides maps and images of nine stops in downtown Utica associated with the Underground Railroad. Developed in partnership with the 
                                Oneida County Freedom Trail Commission and is based on tours previously given by the Commission. 
                                Click the map below to begin your tour.
                                </p>
                                <a target="_blank" href="https://theclio.com/tour/2012">
                                    <img decoding='async' loading='lazy' alt='google maps trail' src="assets/TEMP-trail.png"/>
                                </a>
                            </div>
                            <a class="main-button mobile-read-more">Read More</a>
                            <a class="main-button mobile-close">Close</a>
                        </div>
                    </div>
                    <div className='white-card-body hide'>
                        <div class="card white-card">
                            <div class="card-content">
                                <div>
                                    <img decoding='async' loading='lazy' alt='utica shoes exhibit' class="white-card-image" src="assets/visit/exhibits/ochc-shoes.webp"/>
                                </div>
                                <div class="card-text">
                                    <h3 className='card-title'>Utica Shoes? Who Knew?</h3>
                                    <div className='card-second-child short-text'>
                                        <p className='fade-in-up'>
                                        Did you know that the city of Utica once produced 2,000 pairs of shoes per day?! This new exhibit features photographs, tools, advertising, and shoes! It explores the lesser-known shoe industry tha...
                                        </p>
                                    </div>
                                    <div className='card-second-child full-text'>
                                        <p className='fade-in-up'>
                                        Did you know that the city of Utica once produced 2,000 pairs of shoes per day?! This new exhibit features photographs, tools, advertising, and shoes! It explores the lesser-known shoe industry that thrived in Utica and was one of its chief industries.
                                        </p>
                                        <h5 className='fade-in-up'>Utica's Historic Shoe District Walking Tour</h5>
                                        <p className='fade-in-up'>
                                        This self-guided tour features the sight of several factories in Utica and traces the rise and fall of an amazing part of the area's history. Click the map below to start your tour!
                                        </p>
                                        <a target="_blank" href="https://www.theclio.com/entry/149784/tour">
                                            <img decoding='async' loading='lazy' alt='google map trail' src="assets/TEMP-shoestrail.png"/>
                                        </a>
                                        <br/><br/>
                                        <h5 className='fade-in-up'>Companion Lecture</h5>
                                        <p className='fade-in-up'>
                                        OCHC Director of Public Programs Patrick Reynolds discusses his research and findings on this vanished Utica industry.
                                        </p>
                                        <iframe width="600" height="350" src="https://www.youtube.com/embed/Ai8n4wlIG5A?si=KjmTFdHD0tqb41da" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        <br/>
                                    </div>
                                    <a className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mobile-card hide">
                        <div>
                            <img decoding='async' loading='lazy' alt='utica shoes exhibit' class="white-card-image" src="assets/visit/exhibits/ochc-shoes.webp"/>
                        </div>
                        <div class="mobile-content">
                            <h3 className='card-title'>Utica Shoes? Who Knew?</h3>
                            <div className='mobile-short-text'>
                                <p>
                                Did you know that the city of Utica once produced 2,000 pairs of shoes per day?! This new exhibit features photographs, tools, advertising, and shoes! It explores the lesser-known shoe industry tha...
                                </p>
                            </div>
                            <div className='mobile-full-text'>
                                <p>
                                Did you know that the city of Utica once produced 2,000 pairs of shoes per day?! This new exhibit features photographs, tools, advertising, and shoes! It explores the lesser-known shoe industry that thrived in Utica and was one of its chief industries.
                                </p>
                                <h5>Utica's Historic Shoe District Walking Tour</h5>
                                <p>
                                This self-guided tour features the sight of several factories in Utica and traces the rise and fall of an amazing part of the area's history. Click the map below to start your tour!
                                </p>
                                <a target="_blank" href="https://www.theclio.com/entry/149784/tour">
                                    <img decoding='async' loading='lazy' alt='google map trail' src="assets/TEMP-shoestrail.png"/>
                                </a>
                                <br/><br/>
                                <h5>Companion Lecture</h5>
                                <p>
                                OCHC Director of Public Programs Patrick Reynolds discusses his research and findings on this vanished Utica industry.
                                </p>
                                <iframe width="600" height="350" src="https://www.youtube.com/embed/Ai8n4wlIG5A?si=KjmTFdHD0tqb41da" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                <br/>
                            </div>
                            <a class="main-button mobile-read-more">Read More</a>
                            <a class="main-button mobile-close">Close</a> 
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Exhibits;