import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../LoadFadeIn';
import { FadeIn } from '../FadeIn';
import ReactGA from 'react-ga';

const OurHistory = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();
                
        const cards = document.querySelectorAll('.white-card');

        if (window.innerWidth > 900) {

            cards.forEach(function (card) {
                const cardContent = card.querySelector(".card-content");
                const expandButton = card.querySelector('.expand-button');
                const whiteCardImg = card.querySelector('.white-card-image');

                const cardTextsShorts = card.querySelector('.short-text');
                const cardTextsFulls = card.querySelector('.full-text');
        
                let isExpanded = false;
        
                cardContent.style.display = "flex";
                cardContent.style.height = "400px";
                whiteCardImg.style.width = "600px";
                whiteCardImg.style.height = "400px";

                cardTextsFulls.style.display = "none";
                cardTextsShorts.style.display = "block";
                
        
                expandButton.addEventListener('click', function () {


                    // Your click event code for each button goes here
                    if (!isExpanded) {
                        // Expand the card
                        cardContent.style.display = "block";
                        cardContent.style.height = "100%";
                        whiteCardImg.style.width = "100%";
                        whiteCardImg.style.height = "520px";

                        cardTextsFulls.style.display = "block";
                        cardTextsShorts.style.display = "none";

                        expandButton.innerText = "CLOSE";
                        expandButton.style.float = 'right';
                        expandButton.style.marginBottom = "1em";
                    } 
                    
                    else {
                        // Collapse the card
                        cardContent.style.display = "flex";
                        cardContent.style.height = "400px";
                        whiteCardImg.style.width = "600px";
                        whiteCardImg.style.height = "400px";

                        cardTextsFulls.style.display = "none";
                        cardTextsShorts.style.display = "block";

                        expandButton.innerText = "READ MORE";
                        expandButton.style.float = 'left';
                        expandButton.style.marginBottom = "0em";
                    }

                    isExpanded = !isExpanded; // Toggle the expanded state
                });
            });
        } 
        
        else {

             // SHOW MOBILE

             const mobileCards = document.querySelectorAll('.mobile-card');
              
             mobileCards.forEach(function (mobileCard) {
               const mobileContent = mobileCard.querySelector('.mobile-content');
               const mobileReadMoreButton = mobileCard.querySelector('.mobile-read-more');
               const mobileCloseButton = mobileCard.querySelector('.mobile-close');
               const mobileFullText = mobileCard.querySelector('.mobile-full-text');
               const mobileShortText = mobileCard.querySelector('.mobile-short-text');

               mobileReadMoreButton.style.color = '#fff';
               mobileCloseButton.style.color = '#fff';
               
               mobileReadMoreButton.style.width = '70%';
               mobileCloseButton.style.width = '70%';
           
               mobileReadMoreButton.addEventListener('click', function () {
                 mobileFullText.style.display = 'block';
                 mobileShortText.style.display = 'none';
                 mobileReadMoreButton.style.display = 'none';
                 mobileCloseButton.style.display = 'block';
               });
           
               mobileCloseButton.addEventListener('click', function () {
                 mobileFullText.style.display = 'none';
                 mobileShortText.style.display = 'block';
                 mobileReadMoreButton.style.display = 'block';
                 mobileCloseButton.style.display = 'none';
               });
             });

        }
    
    }, []);


    return (
        <div id="our-history-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/about/our-history/TEMP-history.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Our History</h1>
                    <h4 className='fade-in-up-load'>
                    Oneida County History Center was founded in 1876 and has 
                    served since that time to collect, preserve and make accessible 
                    the past heritage of Oneida County and the upper Mohawk River Valley.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='top-text container'>
                    <h2>Local History For Everyone</h2>
                    <p>
                    Oneida County History Center is fueled by a passion for history, education, and community. Founded in 1876, our mission is to protect, 
                    preserve, and share the past of Oneida County and central New York. The organization is operated by a small but mighty staff of three, 
                    and the help of more than two dozen dedicated volunteers. Free public programs, rotating exhibits, educational opportunities for all 
                    ages, and preservation of a vast archival and artifact collection are at the core of its mission.
                    </p>
                    <br/>
                </div>
                <div className='dates-row row'>
                    <div className='col-lg-4'>
                        <h1 className='fade-in-up'>1798</h1>
                        <p className='fade-in-up'>The year Oneida County was founded by the New York State Legislature.</p>
                    </div>
                    <div className='col-lg-4 row-2'>
                        <h1 className='fade-in-up'>1876</h1>
                        <p className='fade-in-up'>The year the Oneida County History Center was founded.</p>
                    </div>
                    <div className='col-lg-4 row-3'>
                        <h1 className='fade-in-up'>232,000+</h1>
                        <p className='fade-in-up'>Number of residents in Oneida County.</p>
                    </div>
                </div>
                <br/><br/>
                <div className='white-card-body'>
                    <div class="card white-card">
                        <div class="card-content">
                            <div>
                                <img decoding='async' loading='lazy' alt='Oneida County History Center Building' class="white-card-image" src="assets/about/our-history/genesee372_jpg.webp"/>
                            </div>
                            <div class="card-text">
                                <h3 className='card-title'>Our Home</h3>
                                <div className='card-second-child short-text'>
                                    <p className='fade-in-up'>
                                    On April 28, 2023, our property at 1608 Genesee Street was listed on the National Register of Historic Places, the nation's official list of properties worthy of preservation. The former First Church of Christ, Scientist, the building's cornerstone was laid on ... 
                                    </p>
                                </div>
                                <div className='card-second-child full-text'>
                                    <p className='fade-in-up'>
                                    On April 28, 2023, our property at 1608 Genesee Street was listed on the National Register of Historic Places, the nation's official list of properties worthy of preservation. The former First Church of Christ, Scientist, the building's cornerstone was laid on June 13, 1914. 
                                    </p>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <img className='history-card-img' src="/assets/about/our-history/ochc_img_first-church-christ-scientist-1914_2019.071.1.webp"/>
                                            <p className='fade-in-up image-label'>Laying of the cornerstone of the First Church of Christ, Scientist, 1914</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <img className='history-card-img' src="/assets/about/our-history/genesee1600157.webp"/>
                                            <p className='image-label'>Our Home Before</p>
                                        </div>
                                    </div>
                                    <p className='fade-in-up'>
                                    The building cost around $100,000 to construct and, following Christian Scientist practice, was officially dedicated in May 1924 after the debt was paid off. This beautiful space served the congregation until the late 1980s when the History Center (then Oneida County Historical Society) purchased the property. 
                                    </p>
                                    <p className='fade-in-up'>
                                    Parts of the interior were modernized for functional purposes, but much of the interior retains its original, architectural integrity including Roman square windows, brass neoclassical light fixtures, and stained-glass windows manufactured by Spence, Bell & Co. of Boston.                                   
                                    </p>
                                </div>
                                <a className="main-button expand-button">Read More 
                                </a>  
                            </div>  
                        </div>
                    </div>
                </div>
                <div class="card mobile-card">
                    <div>
                        <img decoding='async' loading='lazy' alt='Oneida County History Center Building' class="white-card-image" src="assets/TEMP-building.png"/>
                    </div>
                    <div class="mobile-content">
                        <h3 className='card-title'>Our Home</h3>
                        <div className='mobile-short-text'>
                            <p>
                            On April 28, 2023, our property at 1608 Genesee Street was listed on the National Register of Historic Places, the nation's official list of properties worthy of preservation. The former First Church of Christ, Scientist, the building's cornerstone was laid on ... 
                            </p>
                        </div>
                        <div className='mobile-full-text'>
                            <p>
                            On April 28, 2023, our property at 1608 Genesee Street was listed on the National Register of Historic Places, the nation's official list of properties worthy of preservation. The former First Church of Christ, Scientist, the building's cornerstone was laid on June 13, 1914. 
                            </p>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img className='history-card-img' src="/assets/about/our-history/ochc_img_first-church-christ-scientist-1914_2019.071.1.webp"/>
                                    <p className='fade-in-up image-label'>Laying of the cornerstone of the First Church of Christ, Scientist, 1914</p>
                                </div>
                                <div className='col-lg-6'>
                                    <img className='history-card-img' src="/assets/about/our-history/genesee1600157.webp"/>
                                    <p className='image-label'>Interior of the Munson Williams Memorial building</p>
                                </div>
                            </div>                            
                            <p>
                            The building cost around $100,000 to construct and, following Christian Scientist practice, was officially dedicated in May 1924 after the debt was paid off. This beautiful space served the congregation until the late 1980s when the History Center (then Oneida County Historical Society) purchased the property. 
                            </p>
                            <p>
                            Parts of the interior were modernized for functional purposes, but much of the interior retains its original, architectural integrity including Roman square windows, brass neoclassical light fixtures, and stained-glass windows manufactured by Spence, Bell & Co. of Boston.                                   
                            </p>
                        </div>
                        <a class="main-button mobile-read-more">Read More</a>
                        <a class="main-button mobile-close">Close</a>
                    </div> 
                </div>
                <div className='white-card-body'>
                    <div class="card white-card">
                        <div class="card-content">
                            <div>
                                <img decoding='async' loading='lazy' alt='Oneida County History Center organizational history' class="white-card-image" src="assets/about/our-history/OCHS735_MunsonWilliamsMemorial.webp"/>
                            </div>
                            <div class="card-text">
                                <h3 className='card-title'>Organizational History </h3>
                                <div className='card-second-child short-text'>
                                    <p className='fade-in-up'>
                                    In 1876, there was excitement surrounding history because of the US centennial and a group of gentlemen met in the Utica office of Mr. Charles Hutchinson on December 1 to begin the work of establishing a historical society for Oneida Count ...
                                    </p>
                                </div>
                                <div className='card-second-child full-text'>
                                    <p className='fade-in-up'>
                                    In 1876, there was excitement surrounding history because of the US centennial and a group of gentlemen met in the Utica office of Mr. Charles Hutchinson on December 1 to begin the work of establishing a historical society for Oneida County. Its first constitution was adopted on December 15,  and two years later, these initial meetings led to the incorporation of “The Oneida Historical Society at Utica.”
                                    </p>
                                    <p className='fade-in-up'>
                                    Horatio Seymour, the Civil War governor of New York, was appointed as the society's first president. Other founding members included Senator Roscoe Conkling, Senator Francis Kernan, Supreme Court Justice Ward Hunt, Utica Mayor Charles Hutchinson, Minister to Ecuador Philo White (grandson of Hugh White, founder of Whitestown), author of <i>Oneida County: An Illustrated History</i> Daniel Wager, and Pomeroy Jones, the first Oneida County Historian.
                                    </p>
                                    <p className='fade-in-up'>
                                    The society's leaders quickly realized they needed a place to house donated historical items, leading to the society establishing its first headquarters in a newly built library in 1879. During the society's early years, it directed the 100th anniversary celebration of the Battle of Oriskany, led the construction of the Oriskany Monument, erected a marker at the site of Old Fort Schuyler, and much more.
                                    </p>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <img className='history-card-img' src="assets/about/our-history/OCHS730MunsonWilliamsMemorial.webp"/>   
                                            <p className='image-label'> Munson Williams Memorial building</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <img className='history-card-img' src="assets/about/our-history/OCHS729_MunsonWilliamsMemorial.webp"/>   
                                            <p className='image-label'>Interior of the Munson Williams Memorial building</p>
                                        </div>
                                    </div>
                                    <p className='fade-in-up'>
                                    In 1896, the society moved to the newly constructed Munson-Williams Memorial building of the Oneida Historical Society, which was located at 600 Park Ave near the corner of John and Elizabeth Streets. From these new headquarters, the society expanded its library and artifact collection, held public lectures, and established the “Hall of Fame of Oneida County” in 1946. The society largely remained at this location from until the 1960s, when they moved to the basement of Fountain Elms.
                                    </p>
                                    <p className='fade-in-up'>
                                    In 1988, the Oneida County Historical Society purchased its current home at 1608 Genesee Street from the Church of Christ, Scientists. After three years of renovations, the society moved its headquarters to this new location in May 1991. To emphasize the society's openness to the public, Oneida County Historical Society was renamed to  Oneida County History Center in January 2017. 
                                    </p>
                                </div>
                                <a className="main-button expand-button">Read More 
                                </a>  
                            </div>  
                        </div>
                    </div>
                </div>
                <div class="card mobile-card">
                    <div>
                        <img decoding='async' loading='lazy' alt='Oneida County History Center organizational history' class="white-card-image" src="assets/TEMP-org.png"/>
                    </div>
                    <div class="mobile-content">
                        <h3 className='card-title'>Organizational History</h3>
                        <div className='mobile-short-text'>
                            <p>
                            In 1876, there was excitement surrounding history because of the US centennial and a group of gentlemen met in the Utica office of Mr. Charles Hutchinson on December 1 to begin the work of establishing a historical society for Oneida Count ...
                            </p>
                        </div>
                        <div className='mobile-full-text'>
                            <p>
                            In 1876, there was excitement surrounding history because of the US centennial and a group of gentlemen met in the Utica office of Mr. Charles Hutchinson on December 1 to begin the work of establishing a historical society for Oneida County. Its first constitution was adopted on December 15,  and two years later, these initial meetings led to the incorporation of “The Oneida Historical Society at Utica.”
                            </p>
                            <p>
                            Horatio Seymour, the Civil War governor of New York, was appointed as the society's first president. Other founding members included Senator Roscoe Conkling, Senator Francis Kernan, Supreme Court Justice Ward Hunt, Utica Mayor Charles Hutchinson, Minister to Ecuador Philo White (grandson of Hugh White, founder of Whitestown), author of <i>Oneida County: An Illustrated History</i> Daniel Wager, and Pomeroy Jones, the first Oneida County Historian.
                            </p>
                            <p>
                            The society's leaders quickly realized they needed a place to house donated historical items, leading to the society establishing its first headquarters in a newly built library in 1879. During the society's early years, it directed the 100th anniversary celebration of the Battle of Oriskany, led the construction of the Oriskany Monument, erected a marker at the site of Old Fort Schuyler, and much more.
                            </p>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img className='history-card-img' src="assets/about/our-history/OCHS730MunsonWilliamsMemorial.webp"/>   
                                    <p className='image-label'> Munson Williams Memorial building</p>
                                </div>
                                <div className='col-lg-6'>
                                    <img className='history-card-img' src="assets/about/our-history/OCHS729_MunsonWilliamsMemorial.webp"/>   
                                    <p className='image-label'>Interior of the Munson Williams Memorial building</p>
                                </div>
                            </div>
                            <p>
                            In 1896, the society moved to the newly constructed Munson-Williams Memorial building of the Oneida Historical Society, which was located at 600 Park Ave near the corner of John and Elizabeth Streets. From these new headquarters, the society expanded its library and artifact collection, held public lectures, and established the “Hall of Fame of Oneida County” in 1946. The society largely remained at this location from until the 1960s, when they moved to the basement of Fountain Elms.
                            </p>
                            <p>
                            In 1988, the Oneida County Historical Society purchased its current home at 1608 Genesee Street from the Church of Christ, Scientists. After three years of renovations, the society moved its headquarters to this new location in May 1991. To emphasize the society's openness to the public, Oneida County Historical Society was renamed to  Oneida County History Center in January 2017. 
                            </p>
                        </div>
                        <a class="main-button mobile-read-more">Read More</a>
                        <a class="main-button mobile-close">Close</a>  
                    </div>  
                </div>
                <div className="map-row row">
                    <div className='col-lg-7'>
                        <div className='google-map'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11654.480941238513!2d-75.2523385!3d43.0914788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9412dc1632acf%3A0xcaba271eb9a1d0b0!2sOneida%20County%20History%20Center!5e0!3m2!1sen!2sus!4v1694956871181!5m2!1sen!2sus" width="1200" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                
                        </div>
                    </div>
                    <div className='map-row-text col-lg-5'>
                        <h2>Our Location</h2>
                        <p className='fade-in-up'>1608 Genesee St, Utica NY 13502</p>
                        <p className='fade-in-up'>Telephone: <span><a href="tel:315-735-3642">315-735-3642</a></span></p>
                        <p className='fade-in-up'>E-mail: <span><a href="mailto:ochc@oneidacountyhistory.org">ochc@oneidacountyhistory.org</a></span></p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default OurHistory;