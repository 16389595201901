import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';  
import ReactGA from 'react-ga';

const Telethon = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

        const carousel = document.querySelector(".carousel"),
        firstImg = carousel.querySelectorAll("img")[0],
        arrowIcons = document.querySelectorAll(".wrapper i");

        let isDragStart = false, isDragging = false, prevPageX, prevScrollLeft, positionDiff;

        const showHideIcons = () => {
            // showing and hiding prev/next icon according to carousel scroll left value
            let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
            arrowIcons[0].style.display = carousel.scrollLeft == 0 ? "none" : "block";
            arrowIcons[1].style.display = carousel.scrollLeft == scrollWidth ? "none" : "block";
        }

        arrowIcons.forEach(icon => {
            icon.addEventListener("click", () => {
                let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
                // if clicked icon is left, reduce width value from the carousel scroll left else add to it
                carousel.scrollLeft += icon.id == "left" ? -firstImgWidth : firstImgWidth;
                setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
            });
        });

        const autoSlide = () => {
            // if there is no image left to scroll then return from here
            if(carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) > -1 || carousel.scrollLeft <= 0) return;

            positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
            let firstImgWidth = firstImg.clientWidth + 14;
            // getting difference value that needs to add or reduce from carousel left to take middle img center
            let valDifference = firstImgWidth - positionDiff;

            if(carousel.scrollLeft > prevScrollLeft) { // if user is scrolling to the right
                return carousel.scrollLeft += positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
            }
            // if user is scrolling to the left
            carousel.scrollLeft -= positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
        }

        const dragStart = (e) => {
            // updating global variables value on mouse down event
            isDragStart = true;
            prevPageX = e.pageX || e.touches[0].pageX;
            prevScrollLeft = carousel.scrollLeft;
        }

        const dragging = (e) => {
            // scrolling images/carousel to left according to mouse pointer
            if(!isDragStart) return;
            e.preventDefault();
            isDragging = true;
            carousel.classList.add("dragging");
            positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
            carousel.scrollLeft = prevScrollLeft - positionDiff;
            showHideIcons();
        }

        const dragStop = () => {
            isDragStart = false;
            carousel.classList.remove("dragging");

            if(!isDragging) return;
            isDragging = false;
            autoSlide();
        }

        carousel.addEventListener("mousedown", dragStart);
        carousel.addEventListener("touchstart", dragStart);

        document.addEventListener("mousemove", dragging);
        carousel.addEventListener("touchmove", dragging);

        document.addEventListener("mouseup", dragStop);
        carousel.addEventListener("touchend", dragStop);


        const cards = document.querySelectorAll('.white-card');

        if (window.innerWidth > 900) {

            cards.forEach(function (card) {

                    const cardContent = card.querySelector(".card-content");
                    const expandButton = card.querySelector('.expand-button');
                    const whiteCardImg = card.querySelector('.white-card-image');
                
                    const cardTextsShorts = card.querySelector('.short-text');
                    const cardTextsFulls = card.querySelector('.full-text');
                
                    let isExpanded = false;
                
                    cardContent.style.display = "flex";
                    cardContent.style.height = "400px";
                    whiteCardImg.style.width = "600px";
                    whiteCardImg.style.height = "400px";
                
                    cardTextsFulls.style.display = "none";
                    cardTextsShorts.style.display = "block";
                
                    expandButton.addEventListener('click', function () {
                        // Your click event code for each button goes here
                        if (!isExpanded) {
                            // Expand the card
                            cardContent.style.display = "block";
                            cardContent.style.height = "100%";
                            whiteCardImg.style.width = "100%";
                            whiteCardImg.style.height = "520px";
                
                            cardTextsFulls.style.display = "block";
                            cardTextsShorts.style.display = "none";
                
                            expandButton.innerText = "CLOSE";
                            expandButton.style.float = 'right';
                            expandButton.style.marginBottom = "1em";
                        } else {
                            // Collapse the card
                            cardContent.style.display = "flex";
                            cardContent.style.height = "400px";
                            whiteCardImg.style.width = "600px";
                            whiteCardImg.style.height = "400px";
                
                            cardTextsFulls.style.display = "none";
                            cardTextsShorts.style.display = "block";
                
                            expandButton.innerText = "READ MORE";
                            expandButton.style.float = 'left';
                            expandButton.style.marginBottom = "0em";
                        }
                
                        isExpanded = !isExpanded; // Toggle the expanded state
                    });
            });
        }

        const openPopupButtons = document.querySelectorAll(".open_popup");
        const popupCloseButtons = document.querySelectorAll(".popup_close");
        const popupBackButtons = document.querySelectorAll(".popup_back");
        const popupBodies = document.querySelectorAll(".popup_body");

        openPopupButtons.forEach(function(button) {
            button.addEventListener("click", function() {
                const popupMain = this.closest(".popup_main");
                const popupBodyToShow = popupMain.querySelector(".popup_body");
                popupBodyToShow.classList.add("popup_body_show");
            });
        });

        popupCloseButtons.forEach(function(button) {
            button.addEventListener("click", function() {
                const popupBodyToHide = this.closest(".popup_body");
                popupBodyToHide.classList.remove("popup_body_show");
            });
        });

        popupBackButtons.forEach(function(button) {
            button.addEventListener("click", function() {
                const popupBodyToHide = this.closest(".popup_body");
                popupBodyToHide.classList.remove("popup_body_show");
            });
        });

    }, []);

    return (
        <div id="telethon-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/about/news/ochctelethonheader.jpeg' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Telethon</h1>
                    <h4 className='fade-in-up-load'>
                    2024 History Center Telethon Presented by Bank of Utica
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='top-text container'>
                    <p className='fade-in-up'>
                    The 2024 History Center Telethon was broadcast live on WUTR-TV and <a target="_blank" href="https://www.cnyhomepage.com/19th-annual-oneida-county-history-center-telethon/">CNYHomepage.com</a> from the History Center’s main gallery on May 15, 2024. More than a dozen behind-the-scenes volunteers, sixteen phone bank volunteers, and the dedicated WUTR crew all played pivotal roles in ensuring the event and broadcast ran smoothly. Hosts Joe Kelly, Rebecca McLain, and Morgan Mielnicki welcomed special guests throughout the evening, including public officials, community leaders, partners, donors, and volunteers who all share a deep passion for and unwavering commitment to local history. The event also featured artifacts from the History Center's collection of vignettes about Historical Hall of Fame inductees.
                    </p>
                    <br/>
                </div>
                <div className='support-body'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <img src='assets/about/news/ochctelethon232.jpg' alt='Telethon'></img>
                        </div>
                        <div className='col-lg-7 telethon-options'>
                            <h2 className='fade-in-up'>Your Support Matters</h2>
                            <p className='fade-in-up'>
                            Community support has an incredible impact because the History Center relies on the generosity of its community and does not receive annual government funding. Donations sustain its annual operating budget and allow staff and volunteers to provide engaging programs, exhibits, and research opportunities. Pledges received during the 2024 telethon are vital to the organization’s success and directly support mission-driven initiatives including:
                            </p>
                            <br/>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <div class="popup_main">
                                        <button class="open_popup">Programs</button>
                                        <div class="popup_body">
                                            <div class="popup_back"></div>
                                            <div class="popup_contain">
                                                <div class="popup_close">x</div>
                                                Monthly, public programs including lectures, films, story hours, and workshops
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div class="popup_main">
                                        <button class="open_popup">Exhibits</button>
                                        <div class="popup_body">
                                            <div class="popup_back"></div>
                                            <div class="popup_contain">
                                                <div class="popup_close">x</div>
                                                Special and temporary exhibits in the History Center’s 5,000-square-foot gallery
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div class="popup_main">
                                        <button class="open_popup">Research</button>
                                        <div class="popup_body">
                                            <div class="popup_back"></div>
                                            <div class="popup_contain">
                                                <div class="popup_close">x</div>
                                                Free access to the research library for students and low-cost access for non-members
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <div class="popup_main">
                                        <button class="open_popup">Preservation</button>
                                        <div class="popup_body">
                                            <div class="popup_back"></div>
                                            <div class="popup_contain">
                                                <div class="popup_close">x</div>
                                                Preservation and care of growing archival, artifact, and genealogical collections
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-8'>
                                    <div class="popup_main">
                                        <button class="open_popup">Internship & Outreach Opportunities</button>
                                        <div class="popup_body">
                                            <div class="popup_back"></div>
                                            <div class="popup_contain">
                                                <div class="popup_close">x</div>
                                                Internship opportunities and educational outreach to area schools and organizations
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                    
                </div>
                <div className='support-body past-telethon'>
                    <div className='row first-row'>
                        <div className='col-lg-7 past-telethon-body'>
                            <h2 className='fade-in-up'>19th Annual History Center Telethon Raises $47,000!!!</h2>
                            <p className='fade-in-up'>
                            The 19th annual Oneida County History Center Telethon presented by Bank of Utica surpassed its $45,000 goal, raising a record-breaking $46,935 to preserve and share the rich history of Oneida County. A heartfelt thank you goes out to all those who watched, participated, joined, renewed their memberships, or donated. 
                            </p>
                        </div>
                        <div className='col-lg-5'>
                            <img src='assets/support/telethonbankofutica.jpg' alt='Telethon 2024'></img>
                        </div>
                    </div>
                    <div className='row second-row'>
                        <div className='col-lg-7 left'>
                            <img src='assets/support/telethonphonecalls.jpg' alt='Telethon 2024'></img>
                        </div>
                        <div className='col-lg-5 right'>
                            <img src='assets/support/telethonrebeccainterview.jpg' alt='Telethon 2024'></img>
                        </div>
                    </div>
                </div>
                <div className='support-body supporters'>
                    <br/>
                    <h2>Thank You to Our Supporters!</h2>
                    <p className='fade-in-up'>History Center staff and Board of Directors would like to extend a special thanks to the Naming, Platinum, Gold, Silver, and Bronze Star sponsors.</p>
                </div>
                <br/><br/>
                <div class="wrapper">
                        <i id="left" class="fa fa-arrow-left"></i>
                        <div class="carousel">
                            <a target='_blank' href="https://johnbrownsells.com/"><img decoding='async' loading='lazy' alt='Coldwell Banker'  src="assets/support/sponsor1.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.bankofutica.com/'><img decoding='async' loading='lazy' alt='Bank of Utica'  src="assets/support/sponsor2.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.carusomclean.com/'><img decoding='async' loading='lazy' alt='Caruso McLean'  src="assets/support/sponsor3.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.cnyhomepage.com/'><img decoding='async' loading='lazy' alt='CNY Homepage'  src="assets/support/sponsor4.png" draggable="false"/></a>
                            <a target="_blank" href='https://szwinsurance.com/'><img decoding='async' loading='lazy' alt='Scalzo, Zogby & Wittig'  src="assets/support/sponsor5.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.staffworkscny.com/'><img decoding='async' loading='lazy' alt='Staffworks'  src="assets/support/staffworkssponsor.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.northwesternmutual.com/financial/advisor/daniel-jones/'><img decoding='async' loading='lazy' alt='Northwestern Mutual'  src="assets/support/sponsor6.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.cnyhomepage.com/'><img decoding='async' loading='lazy' alt='WUTR TV'  src="assets/support/sponsor7.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.romesentinel.com/'><img decoding='async' loading='lazy' alt='Rome Sentinel'  src="assets/support/sponsor8.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.adkfinancialservices.com/'><img decoding='async' loading='lazy' alt='Adirondack Financial Services'  src="assets/support/afk.png" draggable="false"/></a>
                            <a target="_blank" href='https://www.deiorios.com/'><img decoding='async' loading='lazy' alt='DeIorios'  src="assets/support/deiorio.png" draggable="false"/></a>
                        </div>
                        <i id="right" class="fa fa-arrow-right"></i>
                </div>
                <div className='support-body supporters'>
                    <br/>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='supporters-body'>
                                <h3>Naming Sponsor</h3>
                                <p className='fade-in-up'>Bank of Utica</p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='supporters-body'>
                                <h3>Platinum Sponsors</h3>
                                <p className='fade-in-up'>Adirondack Financial Services</p>
                                <p className='fade-in-up'>Alfred and Elizabeth Duffy Fund at the Community Foundation</p>
                                <p className='fade-in-up'>Chris & Virginia Kelly</p>
                                <p className='fade-in-up'>Coleman B. Harding </p>
                                <p className='fade-in-up'>DBJ Wealth Management</p>
                                <p className='fade-in-up'>Oneida County History Center Board of Directors</p>
                                <p className='fade-in-up'>The John Brown Team at Coldwell Banker Faith Properties </p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='supporters-body'>
                                <h3>Gold Sponsors</h3>
                                <p className='fade-in-up'>Caruso McLean & Co. Inc.</p>
                                <p className='fade-in-up'>Catherine & John McEnroe</p>
                                <p className='fade-in-up'>DeIorio's</p>
                                <p className='fade-in-up'>Dennis & Susan Dewey</p>
                                <p className='fade-in-up'>Denyse & Greg Evans</p>
                                <p className='fade-in-up'>Kathleen Sinnott</p>
                                <p className='fade-in-up'>Scalzo, Zogby & Wittig</p>
                                <p className='fade-in-up'>Staffworks</p>
                                <p className='fade-in-up'>Village of Clinton Historian Richard Williams</p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='supporters-body'>
                                <h3>Media Sponsors</h3>
                                <p className='fade-in-up'>Daily Sentinel</p>
                                <p className='fade-in-up'>WUTR</p> 
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='supporters-body'>
                                <h3>Bronze Sponsors</h3>
                                <p className='fade-in-up'>Janice Collier</p>
                                <p className='fade-in-up'>Jon MacDonald</p>
                                <p className='fade-in-up'>William Manfredo</p>
                                <p className='fade-in-up'>Jay-K Lumber</p>
                                <p className='fade-in-up'>Louise Kelly</p>
                                <p className='fade-in-up'>Oneida Chapter NSDAR</p>
                                <p className='fade-in-up'>Jeff Owen</p>
                                <p className='fade-in-up'>Frank Przybycien</p>
                                <p className='fade-in-up'>Jacquelyn R. Schmidt</p>
                                <p className='fade-in-up'>Kay Sinclair</p>
                                <p className='fade-in-up'>Standard Heating, Cooling, & Insulating</p>
                                <p className='fade-in-up'>Elizabeth & Richard Tantillo</p>
                                <p className='fade-in-up'>Towne Engineering</p>
                                <p className='fade-in-up'>Wurth NSI</p>
                                <p className='fade-in-up'>Earle Reed</p>
                                <p className='fade-in-up'>GPO Federal Credit Union</p>
                                <p className='fade-in-up'>Richard T. Brown</p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='supporters-body'>
                                <h3>Silver Sponsors</h3>
                                <p class="fade-in-up">Mary Briggs-Beha</p>
                                <p class="fade-in-up">Joan & William Blanchfield</p>
                                <p class="fade-in-up">Carl Dziekan</p>
                                <p class="fade-in-up">Michael Fischer Advocacy</p>
                                <p class="fade-in-up">Mary Hayes Gordon & Dean Gordon</p>
                                <p class="fade-in-up">Drs. Cynthia Parlato & Doug Hurd</p>
                                <p class="fade-in-up">MARCH Associates F.X. Matt Brewing, Co.</p>
                                <p class="fade-in-up">Mercurio’s Automotive & Collision</p>
                                <p class="fade-in-up">Charlene Morris</p>
                                <p class="fade-in-up">New Hartford Eye Associates</p>
                                <p class="fade-in-up">Joe Silberlicht & Sandy Fenske</p>
                                <p class="fade-in-up">Alex Sisti</p>
                                <p class="fade-in-up">UFA Alumni Association</p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='supporters-body'>
                                <h3>In-Kind Sponsors</h3>
                                <p className='fade-in-up'>Lafa Mediterranean by Zeina's </p>
                                <p className='fade-in-up'>Northland Communications </p>
                                <p className='fade-in-up'>Oneida County Tourism </p>
                                <p className='fade-in-up'>Mark DiOrio & Momentworx Photography</p>
                                <p className='fade-in-up'>BondarTech</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='support-body supporters'>
                    <p>photos by Mark DiOrio, <a href="https://www.momentworx.com/index">Momentworks Photography</a></p>
                    <br/><br/>
                </div>
            </section>
        </div>
    );
}

export default Telethon;