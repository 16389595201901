import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsUticaBoo = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/utica_boo.jpg"/>
                    <div className='row'>
                        <h2 className='fade-in-up'>History Center Seeks Auditions for Utica Boo!</h2>
                    </div>
                    <p>
                    Oneida County History Center is seeking actors for its upcoming stage production of
                    “Utica Boo!” A haunted whodunnit featuring ghosts from historical Utica’s past. Local author,
                    and paranormal investigator, Dennis Webster, wrote this fun and interactive work of historical
                    fiction as a fundraiser for the Oneida County History Center. 
                    </p>
                    <p>
                    The auditions are to fill the role of nine characters including Lady Vadona, a (crystal ball reading
                    fortune teller),  Brittany, a student of history who wishes to contact the historical ghosts to get
                    their first-hand history, and several ghosts of Utica’s past to include Roscoe Conkling, Chester
                    Gillette, Roxalana Druse, Mary Runkle, Erastus Clark, Dr. Amariah Brigham, Leda Beecher, and
                    Mary Runkle
                    </p>
                    <p>
                    Actors interested in auditioning should contact the director, Dennis Webster, 
                    at <a href="mailto:denniswbstr@gmail.com">denniswbstr@gmail.com</a> for a character dossier, script, and to schedule an audition.
                    </p>
                    <p>
                    Oneida County History Center is a private 501(c) (3) not-for-profit educational institution
                    dedicated to preserving the history, heritage, and culture of the Greater Mohawk Valley for
                    present and future generations. Please contact the History Center at 315-735-3642.
                    </p>
                    <br/><br/><br/>
                </div>
            </section>
        </div>
    );
}

export default NewsUticaBoo;