import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsHOF2024 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/OCHC_HOF_COVER.jpg"/>
                    <div className='row'>
                        <h2 className='fade-in-up'>History Center Seeks Hall of Fame Nominations</h2>
                    </div>
                    <p>
                    Oneida County History Center seeks nominations for the 2024 Historical Hall of Fame and Living Legends Awards. This campaign strives to honor outstanding members of the community, past and present, for their service as leaders, pioneers, and advocates, and for their efforts in furthering the region's industries, culture, and educational opportunities. Inductees are honored at an annual awards ceremony held each fall and through a permanent display in the History Center’s exhibit gallery.
                    </p>
                    <br/><br/>
                    <div className='row legends'>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/about/news/HOF20241.JPG"/>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/about/news/HOF20242.JPG"/>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/about/news/HOF20243.JPG"/>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/about/news/HOF20244.JPG"/>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <p>
                    Past inductees have:
                        <li>
                        achieved prominence that reflects credit upon our community
                        </li>
                        <li>
                        made substantial contributions that improved the quality of life in Oneida County
                        </li>
                        <li>
                        enhanced the cultural value and social fabric of the region.
                        </li>
                    </p>
                    <br/><br/>
                    <p>
                    To nominate, complete the online nomination form or mail your nomination and form to:
                    </p>
                    <p><i>
                    Oneida County History Center<br/>
                    Attn: Hall of Fame Committee<br/>
                    1608 Genesee Street<br/>
                    Utica, NY 13502.<br/></i>
                    <br/><br/>
                    Nominations must be accompanied by a complete nomination form and be received in writing by Tuesday, June 11, 2024, to be considered for this year.

                    </p>
                    <p>
                    A list of past inductees can be found <a target="_blank" href="/assets/HallofFameList.pdf">here</a>.
                    </p>
                    <br/>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf6bFu1UeIXD6luszwuT5mMJcqC5J6yVbfg1AdAF6fTXBWGkg/viewform" 
                                target='_blank'
                                className='main-button'>
                                Online Nomination Form
                            </a>
                        </div>
                        <div className='col-lg-4'>
                            <a href="https://drive.google.com/file/d/1onInjvpR6ytPubc5OP4asQ2jExlmG0IF/view?usp=drive_link" 
                                target='_blank'
                                className='main-button'>
                                Mail-in Nomination Form
                            </a>
                        </div>
                        <div className='col-lg-4'>
                            <a href="https://oneidacountyhistory.org/news-3" 
                                target='_blank'
                                className='main-button'>
                                2023 Hall of Fame
                            </a>
                        </div>
                    </div>
                    <br/><br/><br/>
                </div>
            </section>
        </div>
    );
}

export default NewsHOF2024;