import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const FarminIt2 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. April 20 | Utica</h5>
                    <h1>Panel Discussion:  Landmarks Society of Utica at 50</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/landmarks-at-50.jpeg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, April 20, 2024, 2:00 P.M. 
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    <strong>Preserving and promoting OUR uniquely Utica story.</strong>
                    </p>
                    <p>
                    For nearly 50 years, the Landmarks Society of Greater Utica has been the premiere organization in the region, promoting the preservation, restoration, and reuse of historic buildings, neighborhoods, and landscapes. Join current and past leaders of the Landmarks Society of Greater Utica for a panel discussion celebrating fifty years of preservation activism. They will share stories of past successes and challenges and discuss future plans. 
                    </p>
                    <p>
                    The panel will be moderated by David Chanatry and feature Janet Blaney, Michael Bosak, Steve Grant and Jonathan Hansen Brock.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default FarminIt2;