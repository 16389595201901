import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsTelethon24 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/ochctelethon23.jpg" />
                    <p><i>Oneida County History Center 2023 Telethon</i></p>
                    <br/><br/>
                    <div className='row'>
                        <div className='col-lg-9'>                    
                            <h2 className='fade-in-up'>Save the Date! History Center Telethon is May 15!</h2>
                        </div>
                        <div className='col-lg-3'>
                            <a href="https://mail-attachment.googleusercontent.com/attachment/u/1/?ui=2&ik=4b619d1cf7&attid=0.1&permmsgid=msg-f:1795331053596061806&th=18ea4c14c7d2306e&view=att&disp=inline&realattid=f_lujzz6j60&saddbat=ANGjdJ-j2DnvnLsrK7yCIHj6nl9UgoVf5NbLObF85Kd6rRVcxkRDYmTwt4KY4VDCVKyIlm7j0RU4gyg2MK6_vnkcrtrQdvA-9XdTzsPID99QwxcVW8MAAUK0kirWhYEuiRztyjGrIEFNbIoYZwGLPhc8oVZ0Qa5xtdueXHOYHXV5mPRoljTjmcEEk525uBWlT7mzkzkYKa8iCQcp4Z3i86IUZSh8Ck7vjHZGaXZ0jrS6OC5SyWiKahKYdki5sd51F4XYJ3cUYpWdE7uFeU7mzEJ5_ucnnmM0WJZuXVUCUH0NiDDWRXd2VTAd0bGu3TY_OmrNJ-D2i2WQ6vyAnlhJFmNDmFsKV_I4ybsJlT-4mtjd_M5EMyRY-cOzSd_I4ByYsj7N1SEGUprciZUt10YEUutGo6F1eEjGm1IjwDh0XEaKBh8v5JeNFAnUPGzU1b6ZDyvQ-kJA2NlOOeXa3cbsDjvRorPG0vB4Mk7NUOLxcAvE1Q5Rn1hrUQEH0UZBRfxGcMrkCJFIP9zICAMKx002lhpLou19nP6LyljIEY1gBbtfHz4VShRxRTf02ngkzU9tvyMwF4wyjlwlKCXAixHwYZ7wg4CG4Dzy8ANf9BWV7GEm3i9vFEhZh42yh3xGDKvRREJfoEiPfyIY5efOtue8ceei5WhbKS_Ew13GdGGABzH-XYZo2bTmnaM7XSAsYbtgX_fhYShC38qLHFT_10hdjvh3v7Emf7Jo1RwNE2o5OE9cFNDK5dNf54PCVa0bxS2H1DcjApg_aVaY4XP97KFfsLYM0m7eTm1BUAUhituD5Z2BLQZIiYpIelY-YbcmMwLmIGjaMjUOIIOBedI2zEABEuHQIahqKHwgGSjFOU5p2lXw2Wr1k5Q8VHTrFw5OphRL8DFp8l1hcnB_trReBZ3uUNddyx6H4P-_m29XpRsYyOgF5MGW6QZ5uCDNePLSbu82kzStxXNQeFi3LGB7r8rUE3NgVVPaN0wF1X_4V8cbeOGB0pk30yAild2cx9owJ-X1aYmQ4-PsgZB1qiwx_Mp2kLDcufnK6y9CTjvhruhZbAaxgID0L9tPA3yAejjSRFy7z5JJFp7dwKk7WM8oAd11" 
                                target='_blank'
                                className='main-button'>
                                PRESS RELEASE
                            </a>
                            <br/><br/>
                        </div>
                    </div>
                    <p className='fade-in-up'>
                    Oneida County History Center is excited to announce its 19th Annual Telethon presented by Bank of Utica! This signature fundraising event will be held on <strong>Wednesday, May 15, 2024, from 5 - 8 p.m</strong>. Tune in to the live broadcast on WUTR-TV or watch the live stream on CNYHomepage.com. The evening celebrates local history and the community partnerships that
                    make implementing the History Center mission possible.
                    </p>
                    <div className='row' id='telethon-24'>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/ochctelethon231.jpg" />
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/ochctelethon232.jpg" />
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/ochctelethon233.jpg" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default NewsTelethon24;