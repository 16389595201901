import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const FarminIt = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. April 13 | Utica</h5>
                    <h1>Film Screening: <b/><br/>“Farmin’ It”</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/Farminit.png"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, April 13, 2024, 2:00 P.M. 
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    “Farmin’ It,” a new documentary by local filmmaker Dennis Dewey, explores the past, present, and future of one of Oneida County's biggest industries, dairy farming. The story follows three local farms and includes first-hand accounts from different generations. 
                    </p>
                    <p>
                    This film examines the challenges and controversies facing the dairy industry today and seeks to close the consumer’s knowledge gap between farm and table. It premieres at the Oneida County History Center on Saturday, April 13th at 2:00 p.m., and Tuesday, April 16th at 7:00 p.m.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default FarminIt;