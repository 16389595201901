import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const Baca = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Thurs. Nov 21 | Utica</h5>
                    <h1>History of the Statehood of Bosnia and Herzegovina  </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/baca.png"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Thursday, November 21, 7:00 P.M.
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Sandro Sehic, PhD. explains the history of Bosnian statehood and the factors that contributed to the immigration of Bosnians to Utica, NY and Oneida County. This program is presented by the Bosnian American Community Association (BACA) in honor of Bosnia and Herzegovina Statehood Day (November 25). BACA is a nonprofit committed to promoting and empowering Bosnian American heritage and unity in Utica, NY through education, cultural engagement, and active community involvement.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Baca;