export function FadeIn() {

    window.addEventListener('scroll', function() {

        // Function to check if an element is in the viewport
        function isScrolledIntoView(element) {
            var rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    
        // Get all elements with the class 'fade-in-up'
        var fadeElements = document.querySelectorAll('.fade-in-up');
    
        fadeElements.forEach(function(element) {
            var point = "0px", move = "50px";
    
            if (isScrolledIntoView(element)) {
                element.style.opacity = 1;
                element.style.visibility = 'visible';
                element.style.webkitTransform = 'translateY(' + point + ')';
                element.style.transform = 'translateY(' + point + ')';
            } else {
                element.style.opacity = 0;
                element.style.visibility = 'hidden';
                element.style.webkitTransform = 'translateY(' + move + ')';
                element.style.transform = 'translateY(' + move + ')';
            }
        });
    });

}
