import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsPage3 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <img decoding='async' loading='lazy' alt='news event'  className="main-img"src="assets/about/news/HallofFame.JPG"/>
                    <div className='row'>
                        <div className='col-lg-9'>                    
                            <h2 className='fade-in-up'>2023 Historical Hall of Fame!</h2>
                        </div>
                        <div className='col-lg-3'>
                            <a href="https://www.youtube.com/watch?v=fRN9_JX9psQ" 
                                target='_blank'
                                className='main-button'>
                                2023 Induction Video
                            </a>
                            <br/><br/>
                        </div>
                    </div>
                    <p>
                    The Oneida County Historical Hall of Fame has named four individuals to its 2023 inductee class: Robert Everett, Welsh preacher, abolitionist, and printer; Norman Hill, Utica’s first African-American real estate broker; Shirley Waters, pioneering newspaperwoman and arts advocate; and Colonel Marinus Willett, Revolutionary War hero. The Hall of Famers will be inducted alongside the 2023 Richard W. Couper ‘Living Legends’ class: Richard Alexander, entrepreneur and philanthropist; Evon Ervin, African American community, government, and cultural leader; Hon. Anthony J. Garramone, a respected judge and public servant; and Frank Giotto, business leader and founder of Fiber Instrument Sale. Biographies for each inductee are below.
                    </p>
                    <p>
                    The Historical Hall of Fame was started in 1946 and honors individuals from Oneida County who made an impact on our community and the world. Its goal is to ensure that noteworthy people in our community and their achievements are recorded in local history. The Living Legends category was added in 2001 to celebrate our present-day models of people making a difference. Oneida County History Center is excited to continue this tradition and celebrate remarkable individuals from and in our community.
                    </p>
                    <p>
                    The inductees will be honored during the annual Historical Hall of Fame and Living Legends Awards Celebration to be held at the Irish Cultural Center, Utica on Thursday, October 19, 2023, starting at 5:00 pm. Tickets are $60 for current History Center members and $70 for the general public. Tickets should be purchased before October 11 and are available on-site at the History Center, online through its e-Bookstore, or by calling 315-735-3642.  Proceeds from the event will be used will be used to support exhibit enhancements and facility improvements. Sponsorship and advertisement opportunities are available. 
                    </p>
                    <br/><br/>
                    <h2> 2023 Richard W. Couper Living Legends</h2>
                    <div className='row legends'>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='richard alexander' src="assets/about/news/richalex.png"/>
                            <p>Richard Alexander</p>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='evon ervin' src="assets/about/news/evon.png"/>
                            <p>Evon Ervin</p>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='anthony garramone' src="assets/about/news/garramone.png"/>
                            <p>Anthony Garramone</p>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='frank giotto' src="assets/about/news/giotto.png"/>
                            <p>Frank Giotto</p>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <p>
                    <strong><i>Richard E. Alexander</i></strong> is a Navy veteran, entrepreneur, and philanthropist. Born in Utica in 1933, he attended local schools and graduated with honors 
                    from Rensselaer Polytechnic Institute. He was appointed to the US Naval Civil Engineers Corps and attended the Naval School of Justice. He returned 
                    to Utica after his honorable discharge and joined his family business, Dick Tile & Marble Company. In 1974, he founded Alexander and Edgebrook 
                    Construction Companies, where he served as president and CEO until his retirement in 2018. He opened several hotels in the region including 
                    the Ramada Inn New Hartford, Airport Ramada Inn Oriskany, Inn Best Western Carriage House, and the Inn @81 in Watertown. He established 14 other 
                    successful companies including the Mohawk Valley State Bank. Mr. Alexander is an avid philanthropist and has raised money for numerous organizations 
                    including Hope House, Dodge Ponds, Scouting USA, and the Urban Mission Answer to a Prayer. He established a scholarship in his father’s name at Utica 
                    University and his dedication to supporting individuals with disabilities led him to purchase, remodel, and donate the Jefferson Rehabilitation Center. 
                    His work and generosity can be felt throughout central New York and beyond.
                    </p>
                    <p>
                    <strong><i>Evon Ervin</i></strong> is a native of Utica and has been married to Venice Ervin for over 43 years. She continuously advocates for 
                    change and unity in our community, which is demonstrated through her service and leadership. A graduate of Utica College of Syracuse University, she holds 
                    a bachelor of science degree in psychology-child life /child development. She recently retired as Deputy Director for Mohawk Valley Community Action Agency 
                    Inc., the frontline resource for people living in poverty in Oneida and Herkimer Counties. Ms. Ervin is a licensed Evangelist, president of St. Matthew’s 
                    Temple Missionary Board, and Certified Community Action Professional. She was elected as Oneida County’s first female African-American legislator in 2020. 
                    Today, she continues to represent Oneida County Legislator District #20 and serves on the Health & Human Service, Economic Development, and Public Safety 
                    committees. Evon has shared her time and expertise with countless community organizations including Communities That Care, NeighborWorks HomeOwnership Center, 
                    Utica Safe Schools, Utica City School District Board of Education, Utica School District Policy Committee, and BOCES School Board Institute Executive 
                    Committee. She is currently involved with The ARC, Oneida-Lewis Chapter, Oneida County NAACP, Black Leadership Coalition, MVCAA Board Member, 
                    Utica Public Library, and MVCC Alumni Committee.
                    </p>
                    <p>
                    <strong><i>Hon. Anthony J. Garramone</i></strong> was a respected judge with a long and distinguished career in public service. A graduate of Thomas R. 
                    Proctor High School and Utica College, he served in the US Navy and earned a Juris Doctorate from Syracuse University College of Law. Before his judicial 
                    career, he served two terms on the Utica Common Council, as counsel to the City of Utica Board of Education, and on the City of Utica Water Board. He 
                    was elected to the first of three terms as City of Utica Judge in 1981 and was elevated to Chief Judge in 1994. He retired with esteem in 2003. Judge 
                    Garramone is a past presiding member of the Judicial Section of the NYS Bar Association and past president of the NYS Association of City Court Judges. 
                    He taught at Utica College from 1965-2007 and served on the boards of many community organizations including the Utica Children’s Museum, Community 
                    Arts Council, Munson Williams Proctor Arts Institute, Mohawk Valley Community College, and the Mohawk Valley Community College Foundation. Today he 
                    serves as the City of Utica Common Council’s attorney.
                    </p>
                    <p>
                    <strong><i>Frank Giotto</i></strong> is an innovative business leader and community benefactor. He studied electrical technology at Mohawk Valley Community C
                    ollege and mathematics at Utica College and received his graduate degree from SUNY of Cortland. Well-known as the founder of Fiber Instrument Sale (FIS), 
                    he worked as a math teacher before the company’s incorporation in 1985. Today, FIS is one of the leading distributors of fiber optic connectivity equipment 
                    in the nation and serves customers throughout the U.S. and in 110 countries worldwide. Mr. Giotto pioneered the FIS Training Manual, a popular addition to 
                    the fiber optic curriculum taught at many colleges, universities, and technical schools. He is the president and founder of Giotto Enterprises, which is 
                    composed of seven manufacturing companies and proudly employs 450 people in the Mohawk Valley. Frank served on numerous non-profit boards including Utica 
                    College, UCP-United Cerebral Palsy, Utica Symphony, and Hope House. He shares his success with the community and has made major contributions to Utica 
                    Tower, Giotto Center (UCP), Utica Zoo, and Utica College. 
                    </p>
                    <br/><br/>
                    <h2>Historical Hall of Fame Class of 2023</h2>
                    <div className='row legends'>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Robert Everett' src="assets/about/news/everett.jpeg"/>
                            <p>Robert Everett</p>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Norman Hill' src="assets/about/news/normhill.jpeg"/>
                            <p>Norman Hill</p>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Shirley Waters' src="assets/about/news/shirley.jpeg"/>
                            <p>Shirley Waters</p>
                        </div>
                        <div className='col-lg-3'>
                            <img decoding='async' loading='lazy' alt='Marinus Willet' src="assets/about/news/marinus.jpeg"/>
                            <p>Marinus Willet</p>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <p>
                    <strong><i>Robert Everett (1791-1875)</i></strong> was a congregational minister, printer, and noted abolitionist. He emigrated from Wales in 1823 and started 
                    his career in Utica, NY at what now is the Plymouth Bethesda Church. He later relocated to the Remsen-Steuben area, settling at the Capel Ucha. He preached 
                    against slavery far before his contemporaries and started a monthly newsletter Y Cenhadwr Americanaidd, which was distributed to Welsh American 
                    settlements across the United States from 1840-1875.  His goal was to persuade new immigrants to become citizens and voters. He felt that 
                    a united Welsh community could influence and support the movement to abolish slavery. Everett was an active station master in the Underground 
                    Railroad and encouraged the Welsh to support the Civil War. He received permission from Frederick Douglass and Harriet Beecher Stowe to translate 
                    their works into Welsh and was referred to as "our Welsh friend" by Douglass. Everett was inducted into the National Abolition Hall of Fame in Peterborough, 
                    NY in 2022 in recognition of his lifelong leadership in the anti-slavery movement.
                    </p>
                    <p>
                    <strong><i>Norman Hill (1923-2014)</i></strong> was the first African-American real estate broker in the City of Utica and a community leader. Born and 
                    raised in segregated Eufaula, Alabama, Hill moved to Utica in the 1940s after serving his country in the US Air Force. He aspired to purchase his own 
                    home and often worked two jobs to save enough money to achieve this goal. He secured employment as a police officer at Griffiss Air Force Base, 
                    which made it financially possible to purchase his first home. His appreciation for home ownership motivated him to pursue a secondary career as a 
                    real estate broker. He was keenly aware that owning a home was key to building self-esteem, instilling pride, and fostering the economic growth of the 
                    community at large. Hill was also a staunch believer in education and giving back to the community. He was a co-founder of the ALAGA Civic Club, which 
                    sought to encourage children in the Cornhill Community to stay in school, maintain excellent scholastic standards, and attend college. Hill served on 
                    many community boards including the Cosmopolitan Community Center, Mid-Utica Neighborhood Preservation Center, Leisure Time Center, and NAACP.
                    </p>
                    <p>
                    <strong><i>Shirley Waters (1921-2021) </i></strong> was a pioneering newspaperwoman, painter, and community advocate. She attended Rome Free Academy and 
                    graduated from Syracuse University with a BFA in Painting in 1943. She learned the newspaper business at a young age from her family, including her 
                    maternal grandfather, Albert Remington Kessinger, who was publisher of the Rome Sentinel from 1928 to 1941. Shirley served as vice president and 
                    secretary at the Sentinel and was widely admired throughout the newspaper industry. She was an advocate for and a philanthropist of the arts. She 
                    was instrumental in raising funds to restore the Stanley Theater and worked to develop the Griffiss International Outdoor Sculpture Park. Shirley 
                    received many awards for her community service including the Woman of Merit Award for Art, the SUNY College of Technology Distinguished Service 
                    Award, YMCA Salute to Women Award for Art, The Rome Area Chamber of Commerce, Cultural Vitality Award, Rome Rotary Club's Roses for the Living Award, 
                    Rome/Utica/Herkimer Chamber of Commerce Athena Award, and the YWCA of Utica selected her for the Salute to Women Award for distinguished service.
                    </p>
                    <p>
                    <strong><i>Marinus Willet (1740-1830)</i></strong> was a Revolutionary War hero whose actions helped the Continental Army claim victory  Born on Long 
                    Island in 1740, he served in the French and Indian War and was an active member of the Sons of Liberty. He served as second in command to Col. 
                    Peter Gansevoort at Fort Schuyler (Stanwix) in 1777 and led a successful attack against the Native American and loyalist camps during the British 
                    siege of Fort Schuyler in August 1777. He served at the fort until 1778 when he left to join the main army under General Washington. He commanded 
                    all the American troops in the Mohawk Valley from 1781-1783, deterring countless raids by loyalists and leading several counter-attacks, one of 
                    which resulted in the death of Walter Butler, a notorious loyalist leader. After the war, Willett returned to New York City but remained in public 
                    service as a two-term sheriff and one-term mayor. He passed away at 90 years old. The National Park Service visitor center in Rome, NY is named 
                    in his honor.  
                    </p>
                </div>
            </section>
        </div>
    );
}

export default NewsPage3;