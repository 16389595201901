import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const Funtrak = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5> Dec 26 - 28 | Utica</h5>
                    <h1>Holiday Train Display with FUNTRAK               </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/funtrak.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        December 26 - 28
                       <br/>Utica, 1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    The Frankfort United N-Trak (FUNTRAK) Model Railroad Club is returning to the Oneida County History Center for its annual post-holiday exhibition featuring an enchanting N-gauge Train Display. Join us for a fun day with model trains and discussions about rail history with fellow enthusiasts of all ages. This event is free and family-friendly.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Funtrak;