import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';  
import ReactGA from 'react-ga';

const SupportUs = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="support-us-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-support-us.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Support Us</h1>
                    <h4 className='fade-in-up-load'>
                    As a not-for-profit that depends on contributions, we appreciate support of any shape or size!
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='top-text container'>
                    <h2 className='fade-in-up'>Support Us</h2>
                    <p className='fade-in-up'>
                    Your support has an incredible impact because OCHC is primarily funded by private sources and relies on donations as a major source of 
                    income. Donate today to join more than 500 members who already support local history. Oneida County History Center/History Society 
                    is a 501 c-3 nonprofit organization and your charitable contribution is tax deductible.                    
                    </p>
                    <p className='fade-in-up'>Select an icon below to learn more about how you can help preserve Oneida County history!</p>
                    <br/>
                </div>
                <div className='support-body'>
                    <div className='support-row row'>
                        <div className='col-lg-4 row-1'>
                            <div className='row'>
                                <div>
                                    <img decoding='async' loading='lazy'  src="assets/TEMP-donate.png" alt="icon" width="80" height="80" aria-hidden="true" />
                                </div>
                                <p><a href="https://oneidacountyhistory.square.site/donate">DONATE</a></p>
                            </div>
                        </div>
                        <div className='col-lg-4 row-2'>
                            <div className='row'>
                                <div>
                                    <img decoding='async' loading='lazy'  src="assets/TEMP-member.png" alt="icon" width="80" height="80" aria-hidden="true" />
                                </div>
                                <p><a href="/membership">BECOME A MEMBER</a></p>
                            </div>
                        </div>
                        <div className='col-lg-4 row-3'>
                            <div className='row'>
                                <div>
                                    <img decoding='async' loading='lazy'  src="assets/TEMP-volunteer.png" alt="icon" width="80" height="80" aria-hidden="true" />
                                </div>
                                <p><a href="/volunteer">VOLUNTEER</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SupportUs;