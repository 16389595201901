import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const NewsHallofFame2024 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-news-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section news-subpage'>
                <div className='container'>
                    <div>
                        <a href="/news" className='main-button'>
                            <i className='fa fa-arrow-left'></i> Back to All News
                        </a>
                    </div>
                    <br/><br/>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='row'>
                            <h2 className='fade-in-up'>Announcing the Historical Hall of Fame and Living Legends Class of 2024!</h2>
                                <p>
                                The Oneida County Historical Hall of Fame has named four individuals to its 2024 inductee class: Dr. John Cochran, Army Surgeon General and physician to George Washington; Charles Gaetano, founder of Charles A. Gaetano Construction Corporation, entrepreneur and philanthropist; Chauncey Goodrich, Godfather of all modern potatoes; and Carolyn K. Tyler, a barrier-breaking educator. The Hall of Famers will be inducted alongside the 2024 Richard W. Couper ‘Living Legends’ class: Michael Bosak, preservationist; Lucretia Hunt, public servant and educator; Dr. Michael Kelberman, cardiologist and autism advocate; Bonny Niles, historian and community leader; and Michael "Doc" Woods, professor, musician, and composer.
                                </p>
                                <p>
                                The Historical Hall of Fame was started in 1946 and honors individuals from Oneida County who made an impact on our community and the world. Its goal is to ensure that noteworthy people in our community and their achievements are recorded in local history. The Living Legends category was added in 2001 to celebrate our present-day models of people making a difference. Oneida County History Center is excited to continue this tradition and celebrate remarkable individuals from our community.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <img decoding='async' loading='lazy' alt='news event'  className="side-img" src="assets/hall-of-fame-long.jpg"/>
                        </div>
                    </div>
                   
                    <br/>
                    <div className='legends-2024-btns row'>
                        <div className='col-lg-10 row'>
                            <div className='col-lg-4'>
                                <a href="https://dananimey-olney.pixieset.com/oneidacountyhistorycenterlivinglegends2024/" 
                                    target='_blank'
                                    className='main-button'>
                                    View Event Photos
                                </a>
                            </div>
                            <div className='col-lg-4'>
                                <a href="https://www.youtube.com/watch?v=CXk4mSyV7oQ" 
                                    target='_blank'
                                    className='main-button'>
                                    Watch Induction
                                </a>
                            </div>
                            <div className='col-lg-4'>
                                <a href="assets/past-hof-1948-2023.pdf" 
                                    target='_blank'
                                    className='main-button'>
                                    Past Inductees
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-2'>

                        </div>
                    </div>
                    <br/><br/>
                    <p>
                    The inductees were honored during the annual Historical Hall of Fame and Living Legends awards celebration at the Irish Cultural Center in Utica on Thursday, October 10, 2024. Proceeds from the event will support facility improvements and technology upgrades. 
                    <br/><br/>Thank you to our event sponsors!
                    </p>
                    <div className='row legends logos-legends legends-2024'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p>
                                Greg and Denyse Evans <br/>Charles A. Gaetano Construction Corporation <br/>Dr. Brian Gaffney <br/>Frank and Kristine Giotto <br/>Mohawk Valley Health System <br/>Ed and Roma Welsh <br/>Senator James H. Donovan Memorial Fund<br/> Hon. Anthony J. and Anna Marie Garramone<br/> and Melva Max
                                </p>
                            </div>
                            <div className='col-lg-6 row'>
                                 <div className='col'>
                                    <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/about/news/CGCC_logo-4c_new_OL.jpg"/>
                                </div>
                                <div className='col'>
                                    <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/about/news/MVHS_CMYK_Regular.jpg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <h2 className='fade-in-up'>Living Legends Class of 2024!</h2>
                    <div className='row legends legends-2024'>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/Michael Bosak LL-2024.jpg"/>
                            <br/><br/><h2>Michael Bosak</h2>
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/Lucretia Hunt LL-2024.jpg"/>
                            <br/><br/><h2>Lucretia Hunt</h2>
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/Dr. Michael Kelberman LL-2024.jpg"/>
                            <br/><br/><h2>Dr. Michael Kelberman</h2>
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/Bonny Niles LL-2024.jpg"/>
                            <br/><br/><h2>Bonny Niles</h2>
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/Michael 'Doc' Woods LL-2024.jpg"/>
                            <br/><br/><h2>Michael 'Doc' Woods</h2>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <p><strong>Michael J. Bosak</strong>, historic preservationist, advocate, and architect, has been instrumental in the
                    preservation of buildings in Oneida County. He is vice-president and educational director for the
                    Landmarks Society of Greater Utica, long-time editor of The Preservationist Landmarks News
                    Bulletin, and chairs its nominating committee. Mike developed the Old Main Redevelopment
                    Advisory Committee that helped save and repurpose Old Main. He was also instrumental in the
                    Landmarks Society purchase of the Rutger #1 and #3 mansions. Mike is a Notre Dame High
                    School, Mohawk Valley Community College, and SUNY Geneseo alumnus, and obtained his
                    master’s degree in architecture from the University of Buffalo. He worked for the City of Utica
                    engineering department and was the lead architect for the 416th Civil Engineering Squadron at
                    Griffiss Air Force Base from 1989 until 1995.</p><br/>
                    <p><strong>Lucretia Hunt</strong> has made helping others her life’s work. From her days as an English as a second
                    language teacher to her work as Chief Examiner for the New York State Department of
                    Education’s GED Program, Lucretia’s work in education impacted thousands of people of all
                    ages. She taught at Mohawk Valley Community College and Madison-Oneida BOCES. Lucretia
                    has served countless civic and community organizations including the City of Utica Parks
                    Commission, the Utica Municipal Housing Authority/People First Board of Commissioners, the
                    Oneida County Office of the Aging/Continuing Care Advisory Council, Home Ownership Center,
                    50 Forward Mohawk Valley, and the League of Women Voters. She spends weekends
                    supervising and volunteering at Mother Marianne’s West Side Kitchen and has spent decades
                    serving on local, county, and state Democratic committees.</p><br/>
                    <p><strong>Dr. Michael Kelberman</strong> is a cardiologist and autism advocate who makes a difference in
                    people’s lives. He is a senior partner at Central New York Cardiology, medical director of
                    Cardiology at Mohawk Valley Health System (MVHS), and the founder and board president of
                    the Kelberman Center. He was raised in South Utica, attended Utica Free Academy and
                    Hamilton College, and received his medical degree from Upstate Medical University. In 2005,
                    Dr. Kelberman and his wife, Jennifer, founded the Kelberman Center, a leading autism service
                    center for children, adults, and families in the Mohawk Valley and central New York. He is
                    actively involved in community leadership roles, serving on various committees and supporting
                    organizations such as the Masonic Medical Research Institute, United Way of Greater Utica,
                    Upstate Caring Partners, and the Cerebral Palsy Association of the Mohawk Valley.</p><br/>
                    <p><strong>Bonny Niles</strong> is the backbone of the Town of Paris Historical Society and a community leader.
                    She has been a member of the historical society since 1995, became president in 1998, and
                    historian since 2003. Her goal is to collect and preserve the history of the Town of Paris and
                    make it available for all to enjoy. She has been successful in overseeing the operations of the
                    historical society and set up two satellite museums, the Lincoln-Davies and the Doolittle
                    Schoolhouse, a restored one-room school building. Bonny has played an active role in
                    community organizations including the Mohawk Valley Christian Women’s Club, Boys Scouts,
                    and the American Cancer Society. She was named Historian of the Year in 2007 and 2019 by
                    the Oneida County Historians Association.</p><br/>
                    <p><strong>Professor “Doctuh" Michael Woods</strong> is a composer, musician, and educator with an impressive
                    record. His work has been performed across the state and country and he has written more than
                    700 compositions in styles that include choral, orchestral, and chamber works, and jazz combo
                    and big-band charts. Doc was a professor at Hamilton College for thirty-one years. Hired as the
                    jazz program director at Hamilton in 1993, he was promoted in 1998 and was the first African
                    American male to receive tenure at the college. He was later promoted to full professor and
                    named professor emeritus upon retirement in 2024. Woods was actively involved in the local
                    community, serving as a mentor, music coordinator, and organizer for various events leaving a
                    lasting impact on thousands of students. His light, love, and joy of music and life are shared with
                    everyone he has ever met.</p><br/>
                    <br/><br/><br/>
                    <h2 className='fade-in-up'>Hall of Fame Class of 2024!</h2>
                    <br/>
                    <div className='row legends legends-2024'>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/JohnCochran.jpg"/>
                            <br/><br/><h2>Dr. John Cochran <br/>(1730 -1807)</h2>
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/CharlesAGaetano.jpg"/>
                            <br/><br/><h2>Charles A. Gaetano (1923-2022)</h2>
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/potato.jpg"/>
                            <br/><br/><h2>Chauncey Goodrich (1801-1864)</h2>
                        </div>
                        <div className='col'>
                            <img decoding='async' loading='lazy' alt='Past Hall of Fame' src="assets/CarolynKTyler.png"/>
                            <br/><br/><h2>Carolyn K. Tyler <br/>(1922-1960)</h2>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <p><strong>Dr. John Cochran</strong> was a prominent figure who served as a surgeon in the French and Indian
                    War and as Surgeon General of the Army of the Middle Department during the Revolutionary
                    War. He was known for his innovative use of inoculations to combat smallpox among soldiers.
                    His friendship with George Washington and his assistance to the Marquis de Lafayette further
                    solidified his legacy. After the war, he settled in New York City before serving as Commissioner
                    of Loans for the State of New York. He later relocated to Palatine, NY, where he passed away in
                    1807. His son, James, reinterred his remains in Forest Hill Cemetery in 1817.</p><br/>
                    <p><strong>Charles A. Gaetano</strong> was an entrepreneur, philanthropist, and founder of the Charles A. Gaetano
                    Construction Company. Born in Utica to Italian immigrants, he grew up during the Great
                    Depression, learning perseverance, family values, and faith. He served in the US Navy during
                    World War II and stateside during the Korean Conflict. He taught himself masonry and
                    plastering, eventually founding his own company in 1959. Charles and his wife Connie built the
                    business together and the Charles A. Gaetano Construction Corporation grew into one of
                    central New York’s most successful firms. He was also dedicated to community service and
                    supported various organizations including the Central Association for the Blind and Visually
                    Impaired, St. Elizabeth Medical Center Elizabethan Society, and Central New York Veterans
                    Outreach Center, among others. He was posthumously awarded the Mohawk Valley Edge
                    Christopher Destito Award for his lasting community impact. His legacy lives on through his
                    family, his company, and the countless projects and acts of kindness he left behind.</p><br/>
                    <p><strong>Chauncey Goodrich</strong> was an experimental botanist who saved the modern potato and fed the
                    world. Born in Troy, New York, in 1801, he earned a degree from Princeton Theological
                    Seminary and then taught at the Oneida Institute in Whitesboro. He moved to Utica in 1843,
                    where he started a market garden and was a chaplain for the New York State Lunatic Asylum.
                    The potato blight struck in the 1840s and caused widespread famine. Goodrich worked for
                    sixteen years, growing and testing over fifteen thousand potato plants to find a disease-resistant
                    variety. He named it the Garnet Chili. Further experimentation with the Garnet Chili by other
                    scientists yielded the now ubiquitous Burbank Russet. Commonly used for french fries, it is the
                    most widely grown potato in North America. Goodrich’s potato is the genetic ancestor of no
                    fewer than 94 percent of potatoes grown in the 21st century in America and Europe.</p><br/>
                    <p><strong>Carolyn Kelly Tyler</strong> was a trailblazing educator and dedicated community leader. Born in
                    Georgia, she majored in music at Talladega College and attended Howard University. Her
                    career in Utica was marked by historic achievements. In 1949, she became the first Black
                    playground worker for the city’s Recreation Department and was first Black teacher in the Utica
                    Public School System, teaching sixth grade at Potter Street School. Tyler recognized the need
                    for adult education and created Utica’s first elementary-level adult education classes at Potter
                    Street School. She was also involved in numerous civic organizations including the
                    Cosmopolitan Center and the Utica Branch NAACP, and was the organist at Hope Chapel AME
                    Zion Church. Tyler’s contributions were honored with the Carolyn K. Tyler Memorial Award for
                    Good Citizenship at Potter Street School and a scholarship in her name by Lambda Kappa Mu
                    Sorority, Inc., Xi Chapter, to support women returning to education. Though she passed away at
                    37, Carolyn Tyler’s legacy continues to inspire.</p><br/>
                    <br/><br/><br/>
                </div>
            </section>
        </div>
    );
}

export default NewsHallofFame2024;