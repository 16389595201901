import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const AnnualMeeting2024 = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Thurs. Dec 12 | Utica</h5>
                    <h1>Annual Membership Meeting and Reception</h1>
                    <br/><br/>
                    {/* <img decoding='async' loading='lazy' alt='upcoming program' src="assets/TEMP-logo.png"/> */}
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Thursday, December 12, 5:30-7:30 P.M.
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Members and a guest are invited to join us to celebrate our almost century and a half of dedication to local history. We’ll review the year, vote, discuss goals for 2025, and enjoy refreshments, a slideshow, and behind-the-scenes tours. Thank you for being a member!
                    </p>
                    <p>
                    <strong>Program:</strong><br/>
                        <li>
                        Doors open at 5:00 p.m.
                        </li>
                        <li>
                        Annual Meeting 5:30-6:00 p.m.
                        </li>
                        <li>
                        Reception: 6:00-7:30 p.m.
                        </li>
                    </p>
                    <p>
                    Proxy voting is available for those who can not attend. Please read below for more information and to review the meeting minutes from last year.
                    </p>
                    <p>
                    <strong>Registration:</strong> Please RSVP by December 6, 2024. Call <a href="tel:315-735-3642">315-735-3642</a> or email <a href="mailto:ochc@oneidacountyhistory.org ">ochc@oneidacountyhistory.org </a>
                    </p>
                    <p>
                    <strong>By-Laws:</strong> No changes.
                    </p>
                    <a target="_blank" href="assets/2020 OCHC By-Laws final 12.15.22.pdf" className='main-button'>
                        VIEW BY-LAWS
                    </a>
                    <br/><br/><br/>
                    <p>
                    <strong>Board of Directors 2025: </strong>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <li>
                            Lacey Carpenter
                            </li>
                            <li>
                            Johnathon Fiore
                            </li>
                            <li>
                            Julie Gederos
                            </li>
                            <li>
                            Mary Hayes-Gordon
                            </li>
                            <li>
                            John Hobika
                            </li>
                        </div>
                        <div className='col-lg-6'>
                             <li>
                            C. Sonia Martinez
                            </li>
                            <li>
                            Catherine McEnroe
                            </li>
                            <li>
                            Jason Mitchell
                            </li>
                            <li>
                            Lou Parrotta
                            </li>
                            <li>
                            Alecia Pendasulo
                            </li>
                        </div>
                    </div>               
                    </p>
                    <br/>
                    <p>
                        <strong>Proxy Voting:</strong> Members give their vote to the Board proxy who will cast your vote as they would vote.  Your presence is required at the meeting if you want to cast your own vote. To vote by proxy please complete the online form available below.
                    </p>
                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfAS_Ui2Ha6ostekvhIqF2Fhc5UfHALihHittfeTEOomz3S_g/viewform" className='main-button'>
                        Proxy Vote Form
                    </a>
                    <br/><br/><br/>
                    <p>
                    Mail in forms must be received (not postmarked) by December 12, 2023.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default AnnualMeeting2024;